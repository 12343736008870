import { isNil, omitBy } from 'lodash'

import {
  phoneVerificationInterceptor,
  twoFAInterceptor,
} from '@marketplace-web/domain/verification'
import {
  anonIdInterceptor,
  API_BASE_PATH,
  ApiClient,
  ApiResponse,
  appendAcceptWebPHeaderInterceptor,
  csrfTokenInterceptor,
  CURRENT_USER_API_ENDPOINT,
  errorInterceptor,
  localeInterceptor,
  ResponseError,
  proxyApiV2ToMarketplaceWeb,
} from '@marketplace-web/shared/api-client'
import { ClientCookieManager, cookiesDataByName } from '@marketplace-web/shared/cookies'
import { EntryType } from '@marketplace-web/shared/photo-tips'

import { AlertType } from 'constants/admin'
import { EducationType } from 'constants/education'
import { FaqEntryType } from 'constants/faq-entry'
import { ReportReasonCatalogSystem } from 'constants/report-reason'
import { GetCatalogSearchScopeArgs } from 'types/api/arguments/get-catalog-search-scope-args'
import { AcceptOfferArgs } from 'types/api/arguments/accept-offer-args'
import { AddBankAccountArgs } from 'types/api/arguments/add-bank-account-args'
import { AddShippingAddressArgs } from 'types/api/arguments/add-shipping-address-args'
import { AddShippingOrderAddressArgs } from 'types/api/arguments/add-shipping-order-address-args'
import { AddUserAddressArgs } from 'types/api/arguments/add-user-address-args'
import { AddUserAddressesMissingInfoArgs } from 'types/api/arguments/add-user-addresses-missing-info-args'
import { AuthenticateCreditCardArgs } from 'types/api/arguments/authenticate-credit-card-args'
import { BundleOrderReviewArgs } from 'types/api/arguments/bundle-order-review-args'
import { CancelTransactionArgs } from 'types/api/arguments/cancel-transaction-args'
import { ChangeEmailArgs } from 'types/api/arguments/change-email-args'
import { ChangeExistingEmailArgs } from 'types/api/arguments/change-existing-email-args'
import { ChangeForgotPasswordArgs } from 'types/api/arguments/change-forgot-password-args'
import { ChangePasswordArgs } from 'types/api/arguments/change-password-args'
import { ChangePhoneNumberArgs } from 'types/api/arguments/change-phone-number-args'
import { CitiesSearchArgs } from 'types/api/arguments/cities-search-args'
import { CompleteItemArgs } from 'types/api/arguments/complete-item-args'
import { ConfirmDataExportArgs } from 'types/api/arguments/confirm-data-export-args'
import { ContinueSingleCheckoutPaymentArgs } from 'types/api/arguments/continue-single-checkout-payment-args'
import { CreateAdminAlertArgs } from 'types/api/arguments/create-admin-alert-args'
import { CreateBundleTransactionArgs } from 'types/api/arguments/create-bundle-transaction-args'
import { CreateComplaintArgs } from 'types/api/arguments/create-complaint-args'
import { CreateComplaintEscalationArgs } from 'types/api/arguments/create-complaint-escalation-args'
import { CreateCustomReturnShipmentArgs } from 'types/api/arguments/create-custom-return-shipment-args'
import { CreateDeliveryOptionChangeRequestArgs } from 'types/api/arguments/create-delivery-option-change-request-args'
import { CreateDirectDonationOrderArgs } from 'types/api/arguments/create-direct-donation-order-args'
import { CreateDsaNoticeAppealArgs } from 'types/api/arguments/create-dsa-notice-appeal-args'
import { CreateDsaNtdAppealArgs } from 'types/api/arguments/create-dsa-ntd-appeal-args'
import { CreateFeedbackArgs } from 'types/api/arguments/create-feedback-args'
import { CreateItemArgs } from 'types/api/arguments/create-item-args'
import { CreateItemConversationArgs } from 'types/api/arguments/create-item-conversation-args'
import { CreateItemDraftArgs } from 'types/api/arguments/create-item-draft-args'
import { CreateLiveChatTicketArgs } from 'types/api/arguments/create-live-chat-ticket-args'
import { CreateOfferArgs } from 'types/api/arguments/create-offer-args'
import { CreatePaymentsAccountArgs } from 'types/api/arguments/create-payments-account-args'
import { CreatePayoutArgs } from 'types/api/arguments/create-payout-args'
import { CreateProIntegrationsApplicationArgs } from 'types/api/arguments/create-pro-integrations-application-args'
import { CreateSavedSearchArgs } from 'types/api/arguments/create-saved-search-args'
import { CreateShippingDeadlineExtensionArgs } from 'types/api/arguments/create-shipping-deadline-extension-args'
import { CreateSupportTicketArgs } from 'types/api/arguments/create-support-ticket-args'
import { CreateUser2FAArgs } from 'types/api/arguments/create-user-2-fa-args'
import { CreditCardArgs } from 'types/api/arguments/credit-card-args'
import { DeleteFeedbackArgs } from 'types/api/arguments/delete-feedback-args'
import { DeleteFeedbackCommentArgs } from 'types/api/arguments/delete-feedback-comment-args'
import { DeleteLikeMessageArgs } from 'types/api/arguments/delete-like-message-args'
import { DeleteLikeMessagePhotoArgs } from 'types/api/arguments/delete-like-message-photo-args'
import { DeleteMessageNewArgs } from 'types/api/arguments/delete-message-new-args'
import { DeleteNpsSurveyArgs } from 'types/api/arguments/delete-nps-survey-args'
import { DeletePhotoMessageNewArgs } from 'types/api/arguments/delete-photo-message-new-args'
import { EditBundleTransactionArgs } from 'types/api/arguments/edit-bundle-transaction-args'
import { EditFeedbackCommentArgs } from 'types/api/arguments/edit-feedback-comment-args'
import { EditUserFeedbackArgs } from 'types/api/arguments/edit-user-feedback-args'
import { GetAvailableCarriersArgs } from 'types/api/arguments/get-available-carriers-args'
import { GetAvailablePickupPointsArgs } from 'types/api/arguments/get-available-pickup-points-args'
import { GetBannersArgs } from 'types/api/arguments/get-banners-args'
import { GetBookDetailsArgs } from 'types/api/arguments/get-book-details-args'
import { GetBrandsArgs } from 'types/api/arguments/get-brands-args'
import { GetBrazeEventPropertiesArgs } from 'types/api/arguments/get-braze-event-properties-args'
import { GetBumpableItemsArgs } from 'types/api/arguments/get-bumpable-items-args'
import { GetBumpOptionsArgs } from 'types/api/arguments/get-bump-options-args'
import { GetBusinessAccountAddressesArgs } from 'types/api/arguments/get-business-account-addresses-args'
import { GetBusinessAccountInvoiceInstructionsArgs } from 'types/api/arguments/get-business-account-invoice-instructions-args'
import { GetBusinessAccountPoliciesArgs } from 'types/api/arguments/get-business-account-policies-args'
import { GetCatalogDefaultFiltersArgs } from 'types/api/arguments/get-catalog-default-filters-args'
import { GetCatalogFacetedCategoriesArgs } from 'types/api/arguments/get-catalog-faceted-categories-args'
import { GetCatalogFiltersArgs } from 'types/api/arguments/get-catalog-filters-args'
import { GetCatalogInitializerArgs } from 'types/api/arguments/get-catalog-initializer-args'
import { GetCatalogItemsArgs } from 'types/api/arguments/get-catalog-items-args'
import { GetCatalogSearchedFiltersArgs } from 'types/api/arguments/get-catalog-searched-filters-args'
import { GetClosetPromotionArgs } from 'types/api/arguments/get-closet-promotion-args'
import { GetDigitalLabelArgs } from 'types/api/arguments/get-digital-label-args'
import { GetFavouriteItemsArgs } from 'types/api/arguments/get-favourite-items-args'
import { GetFeedbackArgs } from 'types/api/arguments/get-feedback-args'
import { GetFollowUsersArgs } from 'types/api/arguments/get-follow-users-args'
import { GetForgotPasswordConfirmationCodeArgs } from 'types/api/arguments/get-forgot-password-confirmation-code-args'
import { GetGoogleIdTokenArgs } from 'types/api/arguments/get-google-id-token-args'
import { GetHomepageBlockArgs } from 'types/api/arguments/get-homepage-block-args'
import { GetHomepageTabPageArgs } from 'types/api/arguments/get-homepage-tab-page-args'
import { GetInboxConversationsArgs } from 'types/api/arguments/get-inbox-conversations-args'
import { GetInboxListItemArgs } from 'types/api/arguments/get-inbox-list-item-args'
import { GetInfoBannerArgs } from 'types/api/arguments/get-info-banner-args'
import { GetIsAutomatedSupportFeedbackEnabledArgs } from 'types/api/arguments/get-is-automated-support-feedback-enabled-args'
import { GetItemAuthenticityModalArgs } from 'types/api/arguments/get-item-authenticity-modal-args'
import { GetItemEditArgs } from 'types/api/arguments/get-item-edit-args'
import { GetItemPriceSuggestionsArgs } from 'types/api/arguments/get-item-price-suggestions-args'
import { GetItemsByPluginNameArgs } from 'types/api/arguments/get-items-by-plugin-name-args'
import { GetItemSuggestionsArgs } from 'types/api/arguments/get-item-suggestions-args'
import { GetMarkAsSoldItemDetailsArgs } from 'types/api/arguments/get-mark-as-sold-item-details-args'
import { GetMyOrdersArgs } from 'types/api/arguments/get-my-orders-args'
import { GetNearbyDropOffPointsArgs } from 'types/api/arguments/get-nearby-drop-off-points-args'
import { GetNearbyPickupPointsArgs } from 'types/api/arguments/get-nearby-pickup-points-args'
import { GetNearbyShippingOptionsArgs } from 'types/api/arguments/get-nearby-shipping-options-args'
import { GetOfferRequestOptionsArgs } from 'types/api/arguments/get-offer-request-options-args'
import { GetPackageShippingOptionsArgs } from 'types/api/arguments/get-package-shipping-options-args'
import { GetPackageSizesArgs } from 'types/api/arguments/get-package-sizes-args'
import { GetPackageSizesSuggestionArgs } from 'types/api/arguments/get-package-sizes-suggestion-args'
import { GetParcelDetailsArgs } from 'types/api/arguments/get-parcel-details-args'
import { GetPhotosArgs } from 'types/api/arguments/get-photos-args'
import { GetPickUpParcelCodeArgs } from 'types/api/arguments/get-pick-up-parcel-code-args'
import { GetPopularBrandsArgs } from 'types/api/arguments/get-popular-brands-args'
import { GetPriceEstimateWithFeesArgs } from 'types/api/arguments/get-price-estimate-with-fees-args'
import { GetPublicPhotosArgs } from 'types/api/arguments/get-public-photos-args'
import { GetReserveItemDetailsArgs } from 'types/api/arguments/get-reserve-item-details-args'
import { GetRoktTransactionAttributesArgs } from 'types/api/arguments/get-rokt-transaction-attributes-args'
import { GetSavedSearchArgs } from 'types/api/arguments/get-saved-search-args'
import { GetSearchSuggestionsArgs } from 'types/api/arguments/get-search-suggestions-args'
import { GetSellerOfferOptionsArgs } from 'types/api/arguments/get-seller-offer-options-args'
import { GetShipmentInstructionsArgs } from 'types/api/arguments/get-shipment-instructions-args'
import { GetSimilarSoldItemsArgs } from 'types/api/arguments/get-similar-sold-items-args'
import { GetTrackingJourneySummaryArgs } from 'types/api/arguments/get-tracking-journey-summary-args'
import { GetTransactionArgs } from 'types/api/arguments/get-transaction-args'
import { GetTransactionPackageSizesArgs } from 'types/api/arguments/get-transaction-package-sizes-args'
import { getUploadFormModelsArgs } from 'types/api/arguments/get-upload-form-models-args'
import { getUploadFormSearchModelsArgs } from 'types/api/arguments/get-upload-form-search-models-args'
import { GetUserBundleItemsArgs } from 'types/api/arguments/get-user-bundle-items-args'
import { GetUserByIdArgs } from 'types/api/arguments/get-user-by-id-args'
import { GetUserFeedbackArgs } from 'types/api/arguments/get-user-feedback-args'
import { GetUserItemFacetsArgs } from 'types/api/arguments/get-user-item-facets-args'
import { GetUserNotificationsArgs } from 'types/api/arguments/get-user-notifications-args'
import { GetUsersArgs } from 'types/api/arguments/get-users-args'
import { GetVasEntryPointsArgs } from 'types/api/arguments/get-vas-entry-points-args'
import { GetWardrobeItemsArgs } from 'types/api/arguments/get-wardrobe-items-args'
import { HandleRefundOfferAction } from 'types/api/arguments/handle-refund-offer-action'
import { HelpCenterFeedbackArgs } from 'types/api/arguments/help-center-feedback-args'
import { InitiateEscrowPaymentArgs } from 'types/api/arguments/initiate-escrow-payment-args'
import { InitiateSingleCheckoutArgs } from 'types/api/arguments/initiate-single-checkout-args'
import { InitiateSingleCheckoutPaymentArgs } from 'types/api/arguments/initiate-single-checkout-payment-args'
import { ItemUploadConditionsArgs } from 'types/api/arguments/item-upload-conditions-args'
import { MarkAsShippedArgs } from 'types/api/arguments/mark-as-shipped-args'
import { MarkAsSoldArgs } from 'types/api/arguments/mark-as-sold-args'
import { ModerateFeedbackArgs } from 'types/api/arguments/moderate-feedback-args'
import { OrderShippingLabelArgs } from 'types/api/arguments/order-shipping-label-args'
import { PostAdvertisingLeadFormArgs } from 'types/api/arguments/post-advertising-lead-form-args'
import { PostCodeArgs } from 'types/api/arguments/post-code-args'
import { PostLikeMessageArgs } from 'types/api/arguments/post-like-message-args'
import { PostLikeMessagePhotoArgs } from 'types/api/arguments/post-like-message-photo-args'
import { PostReplyArgs } from 'types/api/arguments/post-reply-args'
import { PrepareCreditCardRegistrationArgs } from 'types/api/arguments/prepare-credit-card-registration-args'
import { RegisterCreditCardArgs } from 'types/api/arguments/register-credit-card-args'
import { RejectOfferArgs } from 'types/api/arguments/reject-offer-args'
import { RequestFurtherHelpArgs } from 'types/api/arguments/request-further-help-args'
import { ReserveItemArgs } from 'types/api/arguments/reserve-item-args'
import { SaveShippingOrderContactArgs } from 'types/api/arguments/save-shipping-order-contact-args'
import { SaveTransactionShippingContactArgs } from 'types/api/arguments/save-transaction-shipping-contact-args'
import { SelectTransactionPackageSizeArgs } from 'types/api/arguments/select-transaction-package-size-args'
import { SendEmailVerificationCodeArgs } from 'types/api/arguments/send-email-verification-code-args'
import { SendFeedbackProblemFeedbackArgs } from 'types/api/arguments/send-feedback-problem-feedback-args'
import { SendIntellectualPropertyInfringementArgs } from 'types/api/arguments/send-intellectual-property-infringement-args'
import { SendItemUploadFeedbackArgs } from 'types/api/arguments/send-item-upload-feedback-args'
import { SendNpsSurveyResponseArgs } from 'types/api/arguments/send-nps-survey-response-args'
import { SendOfflineVerificationFeedbackArgs } from 'types/api/arguments/send-offline-verification-feedback-args'
import { SendProductFeedbackArgs } from 'types/api/arguments/send-product-feedback-args'
import { SendRefundOfferArgs } from 'types/api/arguments/send-refund-offer-args'
import { SetBannerAsSeenArgs } from 'types/api/arguments/set-banner-as-seen-args'
import { SetBusinessAccountAddressesArgs } from 'types/api/arguments/set-business-account-addresses-args'
import { SetBusinessAccountPoliciesArgs } from 'types/api/arguments/set-business-account-policies-args'
import { SetConversationAsReadArgs } from 'types/api/arguments/set-conversation-as-read-args'
import { SubmitAutomatedSupportFeedbackArgs } from 'types/api/arguments/submit-automated-support-feedback-args'
import { SubmitPaymentsIdentityArgs } from 'types/api/arguments/submit-payments-identity-args'
import { SubmitSimplifiedPaymentsIdentityArgs } from 'types/api/arguments/submit-simplified-payments-identity-args'
import { SubmitTaxpayersSpecialVerificationFormArgs } from 'types/api/arguments/submit-taxpayers-special-verification-form-args'
import { ToggleBrandFollowArgs } from 'types/api/arguments/toggle-brand-follow-args'
import { ToggleUserFavouriteArgs } from 'types/api/arguments/toggle-user-favourite-args'
import { ToggleUserFollowArgs } from 'types/api/arguments/toggle-user-follow-args'
import { TranslateConversationArgs } from 'types/api/arguments/translate-conversation-args'
import { TwoFactorLoginSubmitArgs } from 'types/api/arguments/two-factor-login-submit-args'
import { TwoFactorSubmitArgs } from 'types/api/arguments/two-factor-submit-args'
import { updateBusinessAccountBankDetailsArgs } from 'types/api/arguments/update-business-account-bank-details-args'
import { UpdateBusinessAccountProfileDetailsArgs } from 'types/api/arguments/update-business-account-profile-details-args'
import { UpdateBusinessAccountRepresentativeDetailsArgs } from 'types/api/arguments/update-business-account-representative-details-args'
import { UpdateCarrierPreferenceArgs } from 'types/api/arguments/update-carrier-preference-args'
import { UpdateCheckoutDataArgs } from 'types/api/arguments/update-checkout-data-args'
import { UpdateCurrentUserArgs } from 'types/api/arguments/update-current-user-args'
import { UpdateCurrentUserRealNameArgs } from 'types/api/arguments/update-current-user-real-name-args'
import { UpdateCurrentUserSettingsArgs } from 'types/api/arguments/update-current-user-settings-args'
import { UpdateDeliveryOptionChangeRequestArgs } from 'types/api/arguments/update-delivery-option-change-request-args'
import { UpdateEprUinArgs } from 'types/api/arguments/update-epr-uin-args'
import { UpdateEscrowPaymentDataArgs } from 'types/api/arguments/update-escrow-payment-data-args'
import { UpdateFaqEntryFeedbackArgs } from 'types/api/arguments/update-faq-entry-feedback-args'
import { UpdateFeedbackRatingOptionsArgs } from 'types/api/arguments/update-feedback-rating-options-args'
import { UpdateFundraiserArgs } from 'types/api/arguments/update-fundraiser-args'
import { UpdateItemArgs } from 'types/api/arguments/update-item-args'
import { UpdateItemDraftArgs } from 'types/api/arguments/update-item-draft-args'
import { UpdateSavedSearchArgs } from 'types/api/arguments/update-saved-search-args'
import { UpdateShippingDeadlineExtensionArgs } from 'types/api/arguments/update-shipping-deadline-extension-args'
import { UpdateShippingPreferenceArgs } from 'types/api/arguments/update-shipping-preference-args'
import { UpdateSingleCheckoutPaymentDataArgs } from 'types/api/arguments/update-single-checkout-payment-data-args'
import { UpdateTaxpayerFormDataArgs } from 'types/api/arguments/update-taxpayer-form-data-args'
import { UpdateUserBundleDiscountsArgs } from 'types/api/arguments/update-user-bundle-discounts-args'
import { UpdateUserLanguageArgs } from 'types/api/arguments/update-user-language-args'
import { UpdateUsername } from 'types/api/arguments/update-username'
import { updateVatNumberArgs } from 'types/api/arguments/update-vat-number-args'
import { updateVatNumberResponse } from 'types/api/arguments/update-vat-number-response'
import { UserBlockArgs } from 'types/api/arguments/user-block-args'
import { ValidateEmailVerificationCodeArgs } from 'types/api/arguments/validate-email-verification-code-args'
import { ValidateSpecialVerificationFormArgs } from 'types/api/arguments/validate-special-verification-form-args'
import { ValidateTaxpayerFormArgs } from 'types/api/arguments/validate-taxpayer-form-args'
import { ValidateUserArgs } from 'types/api/arguments/validate-user-args'
import { GetShippingPreferenceFeedbackConfigurationArgs } from 'types/api/arguments/get-shipping-preference-feedback-configuration-args'
import { AddBankAccountResponse } from 'types/api/response/add-bank-account-response'
import { BankAccountRegistration } from 'types/api/response/bank-account-registration'
import { BankAccountsResp } from 'types/api/response/bank-accounts-resp'
import { BankStatementEducationResp } from 'types/api/response/bank-statement-education-resp'
import { BannersResp } from 'types/api/response/banners-resp'
import { BookDetailsResp } from 'types/api/response/book-details-resp'
import { BrandResp } from 'types/api/response/brand-resp'
import { BrandsResp } from 'types/api/response/brands-resp'
import { BumpMinimumPriceResp } from 'types/api/response/bump-minimum-price-resp'
import { BumpOptionResp } from 'types/api/response/bump-option-resp'
import { BundleSummaryResp } from 'types/api/response/bundle-summary-resp'
import { BusinessAccountAddressesResp } from 'types/api/response/business-account-addresses-resp'
import { BusinessAccountInvoiceInstructionsResp } from 'types/api/response/business-account-invoice-instructions-resp'
import { BusinessAccountPoliciesResp } from 'types/api/response/business-account-policies-resp'
import { BusinessAccountResp } from 'types/api/response/business-account-resp'
import { BusinessAccountsTermsOfUseResp } from 'types/api/response/business-accounts-terms-of-use-resp'
import { BusinessUboAddressResp } from 'types/api/response/business-ubo-address-resp'
import { CarrierPreferenceResp } from 'types/api/response/carrier-preference-resp'
import { CarrierPreferencesResp } from 'types/api/response/carrier-preferences-resp'
import { CheckoutConfigurationResp } from 'types/api/response/checkout-configuration-resp'
import { CitiesSearchResp } from 'types/api/response/cities-search-resp'
import { ClosetPromotionPricingResp } from 'types/api/response/closet-promotion-pricing-resp'
import { ColorsResp } from 'types/api/response/colors-resp'
import { ComplaintResp } from 'types/api/response/complaint-resp'
import { ConversationResp } from 'types/api/response/conversation-resp'
import { CountriesResp } from 'types/api/response/countries-resp'
import { CountriesSelectionResp } from 'types/api/response/countries-selection-resp'
import { CountryBoundsResponse } from 'types/api/response/country-bounds-response'
import { CreateBundleTransactionResp } from 'types/api/response/create-bundle-transaction-resp'
import { CreateDigitalProductPassportDraftItemResp } from 'types/api/response/create-digital-product-passport-draft-item-resp'
import { CreateDirectDonationOrderResp } from 'types/api/response/create-direct-donation-order-resp'
import { CreateDsaNoticeAppealResp } from 'types/api/response/create-dsa-notice-appeal-resp'
import { CreateItemConversationResp } from 'types/api/response/create-item-conversation-resp'
import { CreateLiveChatTicketResp } from 'types/api/response/create-live-chat-ticket-resp'
import { CreateOfferRequestResp } from 'types/api/response/create-offer-request-resp'
import { CreateOfferResp } from 'types/api/response/create-offer-resp'
import { CreatePayoutResp } from 'types/api/response/create-payout-resp'
import { CreatePhotoResp } from 'types/api/response/create-photo-resp'
import { CreateUser2FAResp } from 'types/api/response/create-user-2-fa-resp'
import { CreditCardRegistrationBrandsResp } from 'types/api/response/credit-card-registration-brands-resp'
import { CreditCardRegistrationResp } from 'types/api/response/credit-card-registration-resp'
import { CustomShipmentConfigResp } from 'types/api/response/custom-shipment-config-resp'
import { DataExportStatusResp } from 'types/api/response/data-export-status-resp'
import { DefaultShippingAddressResp } from 'types/api/response/default-shipping-address-resp'
import { DigitalLabelResp } from 'types/api/response/digital-label-resp'
import { DownloadPickupParcelLabelResp } from 'types/api/response/download-pickup-parcel-label-resp'
import { DownloadShippingLabelResp } from 'types/api/response/download-shipping-label-resp'
import { EprCategoriesResp } from 'types/api/response/epr-categories-resp'
import { EprCountriesResp } from 'types/api/response/epr-countries-resp'
import { EprUinListResp } from 'types/api/response/epr-uin-list-resp'
import { EscrowPaymentResp } from 'types/api/response/escrow-payment-resp'
import { FaqEntriesResp } from 'types/api/response/faq-entries-resp'
import { FaqEntriesSearchResp } from 'types/api/response/faq-entries-search-resp'
import { FaqEntryFeedbackResp } from 'types/api/response/faq-entry-feedback-resp'
import { FaqEntryResp } from 'types/api/response/faq-entry-resp'
import { FaqNotLoggedInEntriesResp } from 'types/api/response/faq-not-logged-in-entries-resp'
import { FavouriteItemsResp } from 'types/api/response/favourite-items-resp'
import { FeedbackCommentResp } from 'types/api/response/feedback-comment-resp'
import { FeedbackListResp } from 'types/api/response/feedback-list-resp'
import { FeedbackProblemOptionsResp } from 'types/api/response/feedback-problem-options-resp'
import { FeedbackRatingOptionsResp } from 'types/api/response/feedback-rating-options-resp'
import { FeedbackResp } from 'types/api/response/feedback-resp'
import { GenerateUsernameSuggestionResp } from 'types/api/response/generate-username-suggestion-resp'
import { GetAccountStaffResp } from 'types/api/response/get-account-staff-resp'
import { GetAlertItemResp } from 'types/api/response/get-alert-item-resp'
import { GetAvailableCarriersResp } from 'types/api/response/get-available-carriers-resp'
import { GetAvailablePickupPointsResp } from 'types/api/response/get-available-pickup-points-resp'
import { GetBrazeAuthTokenResp } from 'types/api/response/get-braze-auth-token-resp'
import { GetBrazeEventPropertiesResp } from 'types/api/response/get-braze-event-properties-resp'
import { GetBumpableItemsResp } from 'types/api/response/get-bumpable-items-resp'
import { GetBumpsItemsSelectionBannerResp } from 'types/api/response/get-bumps-items-selection-banner-resp'
import { GetBumpsNewMemberItemUploadMotivationResp } from 'types/api/response/get-bumps-new-member-item-upload-motivation-resp'
import { GetBundleOrderReviewResp } from 'types/api/response/get-bundle-order-review-resp'
import { GetBusinessAccountRepresentativeDetailsResp } from 'types/api/response/get-business-account-representative-details-resp'
import { GetCarrierSettingsFeedbackConfigurationResp } from 'types/api/response/get-carrier-settings-feedback-configuration-resp'
import { GetCatalogDefaultFiltersResp } from 'types/api/response/get-catalog-default-filters-resp'
import { GetCatalogFacetedCategoriesResp } from 'types/api/response/get-catalog-faceted-categories-resp'
import { GetCatalogFiltersFacetsResp } from 'types/api/response/get-catalog-filters-facets-resp'
import { GetCatalogFiltersResp } from 'types/api/response/get-catalog-filters-resp'
import { GetCatalogInitializersResp } from 'types/api/response/get-catalog-initializers-resp'
import { GetCatalogItemsResp } from 'types/api/response/get-catalog-items-resp'
import { GetCatalogSearchedFiltersResp } from 'types/api/response/get-catalog-searched-filters-resp'
import { GetCatalogsResp } from 'types/api/response/get-catalogs-resp'
import { GetCategoryAttributesResp } from 'types/api/response/get-category-attributes-resp'
import { GetCharitiesResponse } from 'types/api/response/get-charities-response'
import { GetCollectionDatesResp } from 'types/api/response/get-collection-dates-resp'
import { GetComplaintCreationDetailsResp } from 'types/api/response/get-complaint-creation-details-resp'
import { GetCountryLanguagesResp } from 'types/api/response/get-country-languages-resp'
import { GetCreditCardsResp } from 'types/api/response/get-credit-cards-resp'
import { GetDataExportLinkResp } from 'types/api/response/get-data-export-link-resp'
import { GetDeadlineExtensionOptionsResp } from 'types/api/response/get-deadline-extension-options-resp'
import { GetDeliveryOptionChangeRequestDetailsResp } from 'types/api/response/get-delivery-option-change-request-details-resp'
import { getDeliveryTypeResp } from 'types/api/response/get-delivery-type-resp'
import { GetDirectDonationConfiguratioResp } from 'types/api/response/get-direct-donation-configuratio-resp'
import { GetDirectDonationOrderResp } from 'types/api/response/get-direct-donation-order-resp'
import { GetDownloadLabelUrlResp } from 'types/api/response/get-download-label-url-resp'
import { GetEscrowOrderComplaintInfoResp } from 'types/api/response/get-escrow-order-complaint-info-resp'
import { GetEscrowOrderItemsResp } from 'types/api/response/get-escrow-order-items-resp'
import { GetEscrowOrderOppositeUserResp } from 'types/api/response/get-escrow-order-opposite-user-resp'
import { GetEscrowOrderShipmentResp } from 'types/api/response/get-escrow-order-shipment-resp'
import { GetFeedbackSummaryResp } from 'types/api/response/get-feedback-summary-resp'
import { GetForgotPasswordConfirmationCodeResp } from 'types/api/response/get-forgot-password-confirmation-code-resp'
import { GetFundraiserResponse } from 'types/api/response/get-fundraiser-response'
import { GetGoogleIdTokenResp } from 'types/api/response/get-google-id-token-resp'
import { GetHelpCenterLinkForUserMigrationResp } from 'types/api/response/get-help-center-link-for-user-migration-resp'
import { GetIconsExplanationResp } from 'types/api/response/get-icons-explanation-resp'
import { GetIncomeSettingsResp } from 'types/api/response/get-income-settings-resp'
import { GetInfoBoardPostResp } from 'types/api/response/get-info-board-post-resp'
import { GetInfoBoardPostsResp } from 'types/api/response/get-info-board-posts-resp'
import { GetInvoicesAvailableDatesResp } from 'types/api/response/get-invoices-available-dates-resp'
import { GetInvoicesByDateResp } from 'types/api/response/get-invoices-by-date-resp'
import { GetIsAlertsGeneratorBusyResp } from 'types/api/response/get-is-alerts-generator-busy-resp'
import { GetIsFundraiserEnabledResp } from 'types/api/response/get-is-fundraiser-enabled-resp'
import { GetIsNewComplaintViewResp } from 'types/api/response/get-is-new-complaint-view-resp'
import { GetItemEscrowFeesResp } from 'types/api/response/get-item-escrow-fees-resp'
import { GetItemUploadCatalogsResp } from 'types/api/response/get-item-upload-catalogs-resp'
import { GetItemUploadFeedbackRatingsResp } from 'types/api/response/get-item-upload-feedback-ratings-resp'
import { getKycPhotoTipTypeResp } from 'types/api/response/get-kyc-photo-tip-type-resp'
import { GetLabelOptionsResp } from 'types/api/response/get-label-options-resp'
import { GetLanguagesResp } from 'types/api/response/get-languages-resp'
import { GetMigrationStatusResp } from 'types/api/response/get-migration-status-resp'
import { GetModelAttributeConfigurationResp } from 'types/api/response/get-model-attribute-configuration-resp'
import { GetNationalitiesResp } from 'types/api/response/get-nationalities-resp'
import { GetNotificationLandingsResp } from 'types/api/response/get-notification-landings-resp'
import { GetOfferRequestOptionsResp } from 'types/api/response/get-offer-request-options-resp'
import { GetOfflineVerificationEligibiltyCriteriaResp } from 'types/api/response/get-offline-verification-eligibilty-criteria-resp'
import { GetOfflineVerificationFeedbackResp } from 'types/api/response/get-offline-verification-feedback-resp'
import { GetPackageShippingOptionsResp } from 'types/api/response/get-package-shipping-options-resp'
import { GetPackageSizesSuggestionResp } from 'types/api/response/get-package-sizes-suggestion-resp'
import { GetParcelDetailsResp } from 'types/api/response/get-parcel-details-resp'
import { GetPayoutConfigurationResp } from 'types/api/response/get-payout-configuration-resp'
import { GetPhotosResp } from 'types/api/response/get-photos-resp'
import { GetPriceEstimateWithFeesResponse } from 'types/api/response/get-price-estimate-with-fees-response'
import { GetPrivacyPolicyResp } from 'types/api/response/get-privacy-policy-resp'
import { GetPublicPhotosResp } from 'types/api/response/get-public-photos-resp'
import { GetReferrerResp } from 'types/api/response/get-referrer-resp'
import { GetRefundOfferEstimateDetailsResp } from 'types/api/response/get-refund-offer-estimate-details-resp'
import { GetRequestReturnDetailsResp } from 'types/api/response/get-request-return-details-resp'
import { GetReturnShippingOptionsResp } from 'types/api/response/get-return-shipping-options-resp'
import { GetRoktTransactionAttributesResp } from 'types/api/response/get-rokt-transaction-attributes-resp'
import { GetSavedSearchResp } from 'types/api/response/get-saved-search-resp'
import { GetSellerFiltersResp } from 'types/api/response/get-seller-filters-resp'
import { GetSellerInsightsPhotoRecommendationsResp } from 'types/api/response/get-seller-insights-photo-recommendations-resp'
import { GetSellerInsightsResp } from 'types/api/response/get-seller-insights-resp'
import { GetSellerOfferOptionsResp } from 'types/api/response/get-seller-offer-options-resp'
import { GetSessionDefaultsResp } from 'types/api/response/get-session-defaults-resp'
import { GetShipmentDetailsResp } from 'types/api/response/get-shipment-details-resp'
import { GetShipmentItemsResp } from 'types/api/response/get-shipment-items-resp'
import { GetShippingDetailsResp } from 'types/api/response/get-shipping-details-resp'
import { GetShippingInstructionsResp } from 'types/api/response/get-shipping-instructions-resp'
import { GetShippingPreferencesResp } from 'types/api/response/get-shipping-preferences-resp'
import { GetShowItemUploadFeedbackResp } from 'types/api/response/get-show-item-upload-feedback-resp'
import { GetSimilarSoldItemsResp } from 'types/api/response/get-similar-sold-items-resp'
import { GetSizeGroupsResp } from 'types/api/response/get-size-groups-resp'
import { GetSortedSavedSearchesResp } from 'types/api/response/get-sorted-saved-searches-resp'
import { GetStaffAccountAssertionResp } from 'types/api/response/get-staff-account-assertion-resp'
import { GetSustainabilityImpactResp } from 'types/api/response/get-sustainability-impact-resp'
import { GetCategoryTipsResp } from 'types/api/response/get-category-tips-resp'
import { GetTaxAddressResp } from 'types/api/response/get-tax-address-resp'
import { GetTaxpayerCenterConfigResp } from 'types/api/response/get-taxpayer-center-config-resp'
import { GetTaxpayerNavigationInfoResp } from 'types/api/response/get-taxpayer-navigation-info-resp'
import { GetTaxpayerReportsResp } from 'types/api/response/get-taxpayer-reports-resp'
import { GetTaxpayersSpecialVerificationDeadlineResp } from 'types/api/response/get-taxpayers-special-verification-deadline-resp'
import { GetTaxpayersSpecialVerificationFormConfigurationResp } from 'types/api/response/get-taxpayers-special-verification-form-configuration-resp'
import { GetTaxRulesResp } from 'types/api/response/get-tax-rules-resp'
import { GetTermsAndConditionsContentResp } from 'types/api/response/get-terms-and-conditions-content-resp'
import { GetTermsAndConditionsResp } from 'types/api/response/get-terms-and-conditions-resp'
import { GetTrackerAttributesResp } from 'types/api/response/get-tracker-attributes-resp'
import { GetTransactionResp } from 'types/api/response/get-transaction-resp'
import { GetTwoFADetailsResp } from 'types/api/response/get-two-fa-details-resp'
import { GetUploadFormModelsResp } from 'types/api/response/get-upload-form-models-resp'
import { GetUserBuyCountResp } from 'types/api/response/get-user-buy-count-resp'
import { GetUsernameChangeAvailabilityResp } from 'types/api/response/get-username-change-availability-resp'
import { GetUserNotificationsResp } from 'types/api/response/get-user-notifications-resp'
import { GetUserObligationsResp } from 'types/api/response/get-user-obligations-resp'
import { GetUserSessionsResp } from 'types/api/response/get-user-sessions-resp'
import { GetVasEntryPointsResp } from 'types/api/response/get-vas-entry-points-resp'
import { GetVideoGameRatingsResp } from 'types/api/response/get-video-game-ratings-resp'
import { GetWalletStatementAvailableResp } from 'types/api/response/get-wallet-statement-available-resp'
import { GetWardrobeItemsResp } from 'types/api/response/get-wardrobe-items-resp'
import { HomepageBlockResp } from 'types/api/response/homepage-block-resp'
import { HomepageTabResp } from 'types/api/response/homepage-tab-resp'
import { InboxConversationsResp } from 'types/api/response/inbox-conversations-resp'
import { InboxListItemResp } from 'types/api/response/inbox-list-item-resp'
import { InfoBannerResp } from 'types/api/response/info-banner-resp'
import { InfoBoxResp } from 'types/api/response/info-box-resp'
import { InviteStaffResp } from 'types/api/response/invite-staff-resp'
import { IsAutomatedResponseFeedbackEnabledResp } from 'types/api/response/is-automated-response-feedback-enabled-resp'
import { ItemAuthenticityModalResp } from 'types/api/response/item-authenticity-modal-resp'
import { ItemEditResp } from 'types/api/response/item-edit-resp'
import { ItemFacetsResp } from 'types/api/response/item-facets-resp'
import { ItemPerformanceResponse } from 'types/api/response/item-performance-response'
import { ItemsByPluginNameResponse } from 'types/api/response/items-by-plugin-name-response'
import { ItemSuggestionsResponse } from 'types/api/response/item-suggestions-response'
import { ItemUploadConditionsResp } from 'types/api/response/item-upload-conditions-resp'
import { ItemUploadConfigurationResp } from 'types/api/response/item-upload-configuration-resp'
import { KycEducationInfoResp } from 'types/api/response/kyc-education-info-resp'
import { LiveChatAvailabilityResp } from 'types/api/response/live-chat-availability-resp'
import { MarkAsSoldItemDetailsResp } from 'types/api/response/mark-as-sold-item-details-resp'
import { MarkAsSoldResp } from 'types/api/response/mark-as-sold-resp'
import { MyOrdersResp } from 'types/api/response/my-orders-resp'
import { NearbyDropOffPointResp } from 'types/api/response/nearby-drop-off-point-resp'
import { NearbyPickupPointsResp } from 'types/api/response/nearby-pickup-points-resp'
import { NearbyShippingOptionsResp } from 'types/api/response/nearby-shipping-options-resp'
import { NewPhoneNumberResp } from 'types/api/response/new-phone-number-resp'
import { NotificationSettingsResp } from 'types/api/response/notification-settings-resp'
import { OfflineVerificationElgibilityCheckResp } from 'types/api/response/offline-verification-elgibility-check-resp'
import { OrderShippingLabelResp } from 'types/api/response/order-shipping-label-resp'
import { PackageSizesResp } from 'types/api/response/package-sizes-resp'
import { PayInMethodsResp } from 'types/api/response/pay-in-methods-resp'
import { PaymentsAccountResp } from 'types/api/response/payments-account-resp'
import { PaymentsIdentityFormConfigurationResp } from 'types/api/response/payments-identity-form-configuration-resp'
import { PayoutResp } from 'types/api/response/payout-resp'
import { PhotoTipResp } from 'types/api/response/photo-tip-resp'
import { PhotoTipsResp } from 'types/api/response/photo-tips-resp'
import { PickUpParcelCodeResponse } from 'types/api/response/pick-up-parcel-code-response'
import { PostCodeResponse } from 'types/api/response/post-code-response'
import { PostLikeMessagePhotoResp } from 'types/api/response/post-like-message-photo-resp'
import { PostLikeMessageResp } from 'types/api/response/post-like-message-resp'
import { PostReplyNewError } from 'types/api/response/post-reply-new-error'
import { PrepareBumpOrderResp } from 'types/api/response/prepare-bump-order-resp'
import { PrepareCreditCardRegistrationResp } from 'types/api/response/prepare-credit-card-registration-resp'
import { PriceSuggestionsResp } from 'types/api/response/price-suggestions-resp'
import { ProfileUserInfoResp } from 'types/api/response/profile-user-info-resp'
import { PromotedClosetOrderResp } from 'types/api/response/promoted-closet-order-resp'
import { PromotedClosetsListResp } from 'types/api/response/promoted-closets-list-resp'
import { RecentTransactionsResp } from 'types/api/response/recent-transactions-resp'
import { RecentUsersResp } from 'types/api/response/recent-users-resp'
import { ReferralInviteInstructionsResp } from 'types/api/response/referral-invite-instructions-resp'
import { ReferralRegistrationsResp } from 'types/api/response/referral-registrations-resp'
import { ReferralShareResp } from 'types/api/response/referral-share-resp'
import { RefundResp } from 'types/api/response/refund-resp'
import { RegisterCreditCardResp } from 'types/api/response/register-credit-card-resp'
import { ReportReasonsResp } from 'types/api/response/report-reasons-resp'
import { ReserveItemDetailsResp } from 'types/api/response/reserve-item-details-resp'
import { ReturnLabelOrderResp } from 'types/api/response/return-label-order-resp'
import { ReuploadItemResp } from 'types/api/response/reupload-item-resp'
import { SavedSearchResp } from 'types/api/response/saved-search-resp'
import { SddFormConfigurationResp } from 'types/api/response/sdd-form-configuration-resp'
import { SddMethodsConfigurationResp } from 'types/api/response/sdd-methods-configuration-resp'
import { SddMethodSelectionResp } from 'types/api/response/sdd-method-selection-resp'
import { SddStatusResp } from 'types/api/response/sdd-status-resp'
import { SearchCategoryAttributeOptionsResp } from 'types/api/response/search-category-attribute-options-resp'
import { SearchSuggestionsResp } from 'types/api/response/search-suggestions-resp'
import { SecuritySettingsResp } from 'types/api/response/security-settings-resp'
import { SellerBadgesListResp } from 'types/api/response/seller-badges-list-resp'
import { SellerBadgesResp } from 'types/api/response/seller-badges-resp'
import { SendEmailVerificationCodeResp } from 'types/api/response/send-email-verification-code-resp'
import { SendItemUploadFeedbackResp } from 'types/api/response/send-item-upload-feedback-resp'
import { SendRefundOfferResp } from 'types/api/response/send-refund-offer-resp'
import { ShipmentInstructionsResponse } from 'types/api/response/shipment-instructions-response'
import { ShippingContactConfigurationResp } from 'types/api/response/shipping-contact-configuration-resp'
import { SingleCheckoutPaymentResp } from 'types/api/response/single-checkout-payment-resp'
import { SnadComplaintResp } from 'types/api/response/snad-complaint-resp'
import { SubmitComplaintResp } from 'types/api/response/submit-complaint-resp'
import { SubmitDraftResp } from 'types/api/response/submit-draft-resp'
import { SubmitItemResp } from 'types/api/response/submit-item-resp'
import { SubmitTicketResp } from 'types/api/response/submit-ticket-resp'
import { SystemConfigurationResp } from 'types/api/response/system-configuration-resp'
import { TaxpayerEducationResp } from 'types/api/response/taxpayer-education-resp'
import { TaxpayerFormConfigurationResp } from 'types/api/response/taxpayer-form-configuration-resp'
import { TaxpayerFormStatusResp } from 'types/api/response/taxpayer-form-status-resp'
import { ToggleBrandFollowResp } from 'types/api/response/toggle-brand-follow-resp'
import { ToggleUserFollowResp } from 'types/api/response/toggle-user-follow-resp'
import { TrackingJourneySummaryResponse } from 'types/api/response/tracking-journey-summary-response'
import { TransactionCancellationReasonsResp } from 'types/api/response/transaction-cancellation-reasons-resp'
import { TranslateConversationResp } from 'types/api/response/translate-conversation-resp'
import { TwoFactorResendResp } from 'types/api/response/two-factor-resend-resp'
import { UpdateCheckoutDataResp } from 'types/api/response/update-checkout-data-resp'
import { UpdateShippingPreferenceResp } from 'types/api/response/update-shipping-preference-resp'
import { UploadAnotherItemTipResp } from 'types/api/response/upload-another-item-tip-resp'
import { UploadReportAttachmentResp } from 'types/api/response/upload-report-attachment-resp'
import { UserAddressResp } from 'types/api/response/user-address-resp'
import { UserBundleItemsResp } from 'types/api/response/user-bundle-items-resp'
import { UserPermissionsResp } from 'types/api/response/user-permissions-resp'
import { UserResp } from 'types/api/response/user-resp'
import { UserSizesResp } from 'types/api/response/user-sizes-resp'
import { UsersResp } from 'types/api/response/users-resp'
import { VerificationPromptResp } from 'types/api/response/verification-prompt-resp'
import { WalletBalanceResponse } from 'types/api/response/wallet-balance-response'
import { WalletConversionStatusResp } from 'types/api/response/wallet-conversion-status-resp'
import { WalletInvoicesResp } from 'types/api/response/wallet-invoices-resp'
import { WorldCountriesResp } from 'types/api/response/world-countries-resp'
import { GetCatalogSearchScopeResp } from 'types/api/response/get-catalog-search-scope-resp'

import { BannersDto } from 'types/dtos/banner'
import { BusinessAddressDto } from 'types/dtos/business-address'
import { BusinessRegistrationDto } from 'types/dtos/business-registration'
import { DataExportListDto } from 'types/dtos/data-export'
import { EprInfoDto } from 'types/dtos/epr'
import { RefundEstimateDto } from 'types/dtos/refund-estimate'
import { RefundOfferCreationDetailsDto } from 'types/dtos/refund-offer-creation-details'
import { RefundOfferIdDto } from 'types/dtos/refund-offer-id'
import { WalletConversionErrorDto } from 'types/dtos/business-account'
import { EscrowOrderActonsDto } from 'types/dtos/escrow-order-actions'

import {
  UpdateSingleCheckoutArgs,
  updateSingleCheckoutDataArgsToParams,
  SingleCheckoutResp,
} from '@marketplace-web/domain/checkout-data'

import { addBankAccountArgsToParams } from 'data/api/transformers/arguments/add-bank-account-args-to-params'
import { addNearbyDropOffPointArgsToParams } from 'data/api/transformers/arguments/add-nearby-drop-off-point-args-to-params'
import { addShippingAddressArgsToParams } from 'data/api/transformers/arguments/add-shipping-address-args-to-params'
import { addShippingOrderAddressArgsToParams } from 'data/api/transformers/arguments/add-shipping-order-address-args-to-params'
import { addUserAddressArgsToParams } from 'data/api/transformers/arguments/add-user-address-args-to-params'
import { authenticateCreditCardArgsToParams } from 'data/api/transformers/arguments/authenticate-credit-card-args-to-params'
import { createAdminAlertArgsToParams } from 'data/api/transformers/arguments/create-admin-alert-args-to-params'
import { createDirectDonationOrderArgsToParams } from 'data/api/transformers/arguments/create-direct-donation-order-args-to-params'
import { createLiveChatTicketArgs } from 'data/api/transformers/arguments/create-live-chat-ticket-args'
import { createPaymentsAccountArgsToParams } from 'data/api/transformers/arguments/create-payments-account-args-to-params'
import { createPayoutArgsToParams } from 'data/api/transformers/arguments/create-payout-args-to-params'
import { escrowPaymentDataUpdateArgsToParams } from 'data/api/transformers/arguments/escrow-payment-data-update-args-to-params'
import { escrowPaymentInitiationArgsToParams } from 'data/api/transformers/arguments/escrow-payment-initiation-args-to-params'
import { getCatalogDefaultFiltersArgsToParams } from 'data/api/transformers/arguments/get-catalog-default-filters-args-to-params'
import { getCatalogFacetedCategoriesArgsToParams } from 'data/api/transformers/arguments/get-catalog-faceted-categories-args-to-params'
import { getCatalogFiltersArgsToParams } from 'data/api/transformers/arguments/get-catalog-filters-args-to-params'
import { getCatalogItemsArgsToParams } from 'data/api/transformers/arguments/get-catalog-items-args-to-params'
import { getCatalogSearchedFiltersArgsToParams } from 'data/api/transformers/arguments/get-catalog-searched-filters-args-to-params'
import { getClosetPromotionsArgsToParams } from 'data/api/transformers/arguments/get-closet-promotions-args-to-params'
import { getCreateComplaintArgs } from 'data/api/transformers/arguments/get-create-complaint-args'
import { getCreateDsaNtdAppealArgs } from 'data/api/transformers/arguments/get-create-dsa-ntd-appeal-args'
import { getCreateSupportTicketArgs } from 'data/api/transformers/arguments/get-create-support-ticket-args'
import { getDsaNoticeCreateAppealArgs } from 'data/api/transformers/arguments/get-dsa-notice-create-appeal-args'
import { getMyOrdersArgsToParams } from 'data/api/transformers/arguments/get-my-orders-args-to-params'
import { getNearbyPickupPointsArgsToParams } from 'data/api/transformers/arguments/get-nearby-pickup-points-args-to-params'
import { getNearbyShipmentOptionsArgsToParams } from 'data/api/transformers/arguments/get-nearby-shipment-options-args-to-params'
import { getWardrobeItemsArgsToParams } from 'data/api/transformers/arguments/get-wardrobe-items-args-to-params'
import { postAdvertisingLeadFormArgsToParams } from 'data/api/transformers/arguments/post-advertising-lead-form-args-to-params'
import { registerCreditCardArgsToParams } from 'data/api/transformers/arguments/register-credit-card-args-to-params'
import { sendHelpCenterFeedbackArgsToParams } from 'data/api/transformers/arguments/send-help-center-feedback-args-to-params'
import { sendIntellectualPropertyInfringementArgsToParams } from 'data/api/transformers/arguments/send-intellectual-property-infringement-args-to-params'
import { sendItemUploadFeedbackArgsToParams } from 'data/api/transformers/arguments/send-item-upload-feedback-args-to-params'
import { sendOfflineVerificationFeedbackArgsToParams } from 'data/api/transformers/arguments/send-offline-verification-feedback-args-to-params'
import { setBusinessAccountAddressesArgsToParams } from 'data/api/transformers/arguments/set-business-account-addresses-args-to-params'
import { setBusinessAccountPoliciesArgsToParams } from 'data/api/transformers/arguments/set-business-account-policies-args-to-params'
import { singleCheckoutPaymentContinueArgsToParams } from 'data/api/transformers/arguments/single-checkout-payment-continue-args-to-params'
import { singleCheckoutPaymentInitiationArgsToParams } from 'data/api/transformers/arguments/single-checkout-payment-initiation-args-to-params'
import { submitPaymentsIdentityArgsToParams } from 'data/api/transformers/arguments/submit-payments-identity-args-to-params'
import { submitTaxpayersSpecialVerificationFormArgsToParams } from 'data/api/transformers/arguments/submit-taxpayers-special-verification-form-args-to-params'
import { updateBusinessAccountProfileDetailsArgsToParams } from 'data/api/transformers/arguments/update-business-account-profile-details-args-to-params'
import { updateCheckoutDataArgsToParams } from 'data/api/transformers/arguments/update-checkout-data-args-to-params'
import { updateCurrentUserArgsToParams } from 'data/api/transformers/arguments/update-current-user-args-to-params'
import { updateCurrentUserSettingsArgsToParams } from 'data/api/transformers/arguments/update-current-user-settings-args-to-params'
import { updateFaqEntryFeedbackArgsToParams } from 'data/api/transformers/arguments/update-faq-entry-feedback-args-to-params'
import { updateFeedbackRatingOptionsArgsToParams } from 'data/api/transformers/arguments/update-feedback-rating-options-args-to-params'
import { updateTaxpayerFormDataArgsToParams } from 'data/api/transformers/arguments/update-taxpayer-form-data-args-to-params'
import { updateUserBundleDiscountsArgsToParams } from 'data/api/transformers/arguments/update-user-bundle-discounts-args-to-params'

import * as authInterceptors from './authentication/interceptors'

const multipleSizeGroupsHeader = {
  'X-Enable-Multiple-Size-Groups': 'true',
}

export const moneyObjectHeader = {
  'X-Money-Object': 'true',
}

const noCacheHeader = {
  'Cache-Control': 'no-cache',
}

const cookies = new ClientCookieManager()

const baseURL = API_BASE_PATH

export const api = new ApiClient({ baseURL }, [
  csrfTokenInterceptor,
  errorInterceptor,
  authInterceptors.tokenRefreshInterceptor,
  localeInterceptor,
  phoneVerificationInterceptor,
  twoFAInterceptor,
  anonIdInterceptor,
  cookies.get(cookiesDataByName.supports_webp) ? appendAcceptWebPHeaderInterceptor : undefined,
  cookies.get(cookiesDataByName.proxy_api_v2_feature) ? proxyApiV2ToMarketplaceWeb : undefined,
])

export const getColors = () => api.get<ColorsResp>('/colors')

export const getCatalogs = () => api.get<GetCatalogsResp>('/catalogs')

export const getItemUploadCatalogs = () =>
  api.get<GetItemUploadCatalogsResp>('/item_upload/catalogs')

export const getSizeGroups = () => api.get<GetSizeGroupsResp>('/size_groups')

export const getSizeGroupsByCatalog = (catalogId: number) =>
  api.get<GetSizeGroupsResp>('/size_groups', {
    params: { catalog_ids: catalogId },
    headers: multipleSizeGroupsHeader,
  })

export const getVideoGameRatings = () => api.get<GetVideoGameRatingsResp>('/video_game_ratings')

export const getModelAttributeConfiguration = ({ catalogId, brandId }: getUploadFormModelsArgs) =>
  api.get<GetModelAttributeConfigurationResp>('/item_upload/model_attribute_configuration', {
    params: {
      catalog_id: catalogId,
      brand_id: brandId,
    },
  })

export const getUploadFormModels = ({ catalogId, brandId }: getUploadFormModelsArgs) =>
  api.get<GetUploadFormModelsResp>('item_upload/models', {
    params: {
      catalog_id: catalogId,
      brand_id: brandId,
    },
  })

export const getUploadFormSearchModels = ({
  catalogId,
  brandId,
  searchText,
}: getUploadFormSearchModelsArgs) =>
  api.get<GetUploadFormModelsResp>('item_upload/models_search', {
    params: {
      catalog_id: catalogId,
      brand_id: brandId,
      search_text: searchText,
    },
  })

export const getItemUploadConfiguration = () =>
  api.get<ItemUploadConfigurationResp>('/items/configuration')

export const completeItem = ({ draft, feedbackId, isItemBumped, parcel }: CompleteItemArgs) =>
  api.post<SubmitItemResp>(
    `/item_upload/drafts/${draft.id}/completion`,
    { draft, feedback_id: feedbackId, push_up: isItemBumped, parcel },
    {
      headers: multipleSizeGroupsHeader,
    },
  )

export const createItemDraft = ({ draft, feedbackId, parcel }: CreateItemDraftArgs) =>
  api.post<SubmitDraftResp>(
    '/item_upload/drafts',
    { draft, feedback_id: feedbackId, parcel },
    {
      headers: multipleSizeGroupsHeader,
    },
  )

export const confirmComplaintResolved = (conversationId: number | string) =>
  api.patch<ConversationResp>(`/conversations/${conversationId}/complaint/confirm_resolved`)

export const declineComplaintResolved = (conversationId: number | string) =>
  api.patch<ConversationResp>(`/conversations/${conversationId}/complaint/refuse_resolved`)

export const updateItemDraft = ({ draft, feedbackId, parcel }: UpdateItemDraftArgs) =>
  api.put<SubmitDraftResp>(
    `/item_upload/drafts/${draft.id}`,
    { draft, feedback_id: feedbackId, parcel },
    {
      headers: multipleSizeGroupsHeader,
    },
  )

export const deleteItem = (id: number) => api.post(`/items/${id}/delete`)

export const deleteItemDraft = (id: number) => api.delete(`/item_upload/drafts/${id}`)

export const getItemAuthenticityModal = ({
  catalogId,
  itemId,
  force = false,
  modalDataOnly = false,
}: GetItemAuthenticityModalArgs) =>
  api.get<ItemAuthenticityModalResp>('/items/authenticity_modals', {
    params: {
      catalog_id: catalogId,
      force,
      item_id: itemId,
      modal_data_only: modalDataOnly,
    },
  })

export const getSystemConfiguration = () =>
  api.get<SystemConfigurationResp>('/system_configuration')

// This request function is only created for update_item_price_after_accepted_offer_v2 AB test and won't be scaled
export const updateItemPrice = ({ itemId, itemPrice }: { itemId: number; itemPrice: number }) =>
  api.put(
    `/items/${itemId}`,
    {
      item: { price: itemPrice },
      feedback_id: null,
    },
    {
      headers: moneyObjectHeader,
    },
  )

export const updateItem = ({ item, feedbackId, isItemBumped, parcel }: UpdateItemArgs) =>
  api.put<SubmitItemResp>(
    `/item_upload/items/${item.id}`,
    { item, feedback_id: feedbackId, push_up: isItemBumped, parcel },
    {
      headers: multipleSizeGroupsHeader,
    },
  )

export const modifyItemVisibility = (itemId: number, isHidden: boolean) =>
  api.put<ApiResponse>(`/items/${itemId}/is_hidden`, {
    is_hidden: isHidden,
  })

export const createItem = ({ item, feedbackId, isItemBumped, parcel }: CreateItemArgs) =>
  api.post<SubmitItemResp>(
    '/item_upload/items',
    { item, feedback_id: feedbackId, push_up: isItemBumped, parcel },
    {
      headers: {
        'X-Upload-Form': 'true',
        ...multipleSizeGroupsHeader,
      },
    },
  )

export const getCatalogSearchScope = (args: GetCatalogSearchScopeArgs) =>
  api.get<GetCatalogSearchScopeResp>('/catalog/search_scope', {
    params: {
      search_text: args.searchText,
      catalog_id: args.catalogId,
    },
  })

export const getCatalogItems = (args: GetCatalogItemsArgs) =>
  api.get<GetCatalogItemsResp>('/catalog/items', {
    params: {
      ...getCatalogItemsArgsToParams(args),
      search_by_image_uuid: args.searchByImageUuid,
    },
    headers: moneyObjectHeader,
  })

export const getCatalogInitializers = (args: GetCatalogInitializerArgs) =>
  api.get<GetCatalogInitializersResp>('/catalog/initializers', {
    params: omitBy(args, isNil),
    headers: moneyObjectHeader,
  })

export const getCatalogFacetedCategories = (args: GetCatalogFacetedCategoriesArgs) =>
  api.get<GetCatalogFacetedCategoriesResp>('/catalog/faceted_categories', {
    params: getCatalogFacetedCategoriesArgsToParams(args),
  })

export const getCatalogFilters = (args: GetCatalogFiltersArgs) =>
  api.get<GetCatalogFiltersResp>('/catalog/filters', {
    params: getCatalogFiltersArgsToParams(args),
  })

export const getCatalogDefaultFilters = (args: GetCatalogDefaultFiltersArgs) =>
  api.get<GetCatalogDefaultFiltersResp>('/catalog/filters/defaults', {
    params: getCatalogDefaultFiltersArgsToParams(args),
  })

export const getCatalogSearchedFilters = (args: GetCatalogSearchedFiltersArgs) =>
  api.get<GetCatalogSearchedFiltersResp>('/catalog/filters/search', {
    params: getCatalogSearchedFiltersArgsToParams(args),
  })

export const getCatalogFiltersFacets = (args: GetCatalogFiltersArgs) =>
  api.get<GetCatalogFiltersFacetsResp>('/catalog/filters/facets', {
    params: getCatalogFiltersArgsToParams(args),
  })

export const getPhotoTips = (entryType = EntryType.Default) =>
  api.get<PhotoTipsResp>('/photo_tips', {
    params: { entry_type: entryType },
  })

export const getPhotoTip = (id: number) => api.get<PhotoTipResp>(`/photo_tips/${id}`)

export const getCountries = (locale?: string) =>
  api.get<CountriesResp>('/countries', {
    params: locale ? { locale } : {},
  })

export const getCountriesSelection = () => api.get<CountriesSelectionResp>('/country_selection')

export const searchCities = ({ countryId, searchText }: CitiesSearchArgs) =>
  api.get<CitiesSearchResp>(`/countries/${countryId}/cities`, {
    params: { search_text: searchText || '' },
  })

export const getBrands = ({ keyword, includeAllBrands }: GetBrandsArgs) =>
  api.get<BrandsResp>('/brands', {
    params: omitBy({ keyword, all_brands: includeAllBrands || null }, isNil),
  })

export const dislikeBrand = (itemId: number) => api.post('/brands/dislike', { item_id: itemId })

export const getPopularBrands = ({ letter }: GetPopularBrandsArgs = {}) =>
  api.get<BrandsResp>('/brands/popular', {
    params: {
      letter,
    },
  })

export const getBrandById = (brandId: number) => api.get<BrandResp>(`/brands/${brandId}`)

export const getBrandsFollowedByUser = (userId: number) =>
  api.get<BrandsResp>(`/users/${userId}/brands`)

export const sendHelpCenterFeedback = (args: HelpCenterFeedbackArgs) =>
  api.post('/help_center/feedbacks', sendHelpCenterFeedbackArgsToParams(args))

export const getShowItemUploadFeedback = () =>
  api.get<GetShowItemUploadFeedbackResp>('/items/show_upload_feedback')

export const getItemUploadFeedbackRatings = () =>
  api.get<GetItemUploadFeedbackRatingsResp>('/items/upload_feedback')

export const sendItemUploadFeedback = (args: SendItemUploadFeedbackArgs) =>
  api.put<SendItemUploadFeedbackResp>(
    '/items/upload_feedback',
    sendItemUploadFeedbackArgsToParams(args),
  )

export const sendNpsSurveyResponse = ({
  userId,
  score,
  comment,
  incomplete,
}: SendNpsSurveyResponseArgs) =>
  api.post(`/users/${userId}/nps`, {
    score,
    comment,
    incomplete,
  })

export const deleteNpsSurvey = ({ userId }: DeleteNpsSurveyArgs) =>
  api.delete(`/users/${userId}/nps`)

export const completeTransaction = (id: string | number) =>
  api.put(`/transactions/${id}/complete`, undefined, { headers: moneyObjectHeader })

export const deleteUser = (userId: string | number, notes: string | null | undefined) =>
  api.delete(`/users/${userId}`, {
    params: {
      delete_account_notes: notes,
    },
  })

export const getUserById = ({ id, localize = false }: GetUserByIdArgs) =>
  api.get<UserResp>(`/users/${id}`, { params: { localize } })

export const getHomepageTab = (args: GetHomepageTabPageArgs) =>
  api.get<HomepageTabResp>(`/homepage/${args.tabName}`, {
    params: {
      next_page_token: args.nextPageToken,
      homepage_session_id: args.homepageSessionId,
      column_count: args.columnCount,
      version: 3,
    },
  })

export const prepareClosetPromotionOrder = () =>
  api.post<PromotedClosetOrderResp>('/promoted_closets/orders/prepare', undefined, {
    headers: moneyObjectHeader,
  })

export const getClosetPromotions = (args: GetClosetPromotionArgs) =>
  api.get<PromotedClosetsListResp>('/promoted_closets', {
    params: getClosetPromotionsArgsToParams(args),
    headers: moneyObjectHeader,
  })

export const getWardrobeItems = (args: GetWardrobeItemsArgs) =>
  api.get<GetWardrobeItemsResp>(`/wardrobe/${args.userId}/items`, {
    params: getWardrobeItemsArgsToParams(args),
    headers: moneyObjectHeader,
  })

export const getUserBundleItems = ({
  userId,
  preselectedItemIds,
  currentPage,
  perPage,
}: GetUserBundleItemsArgs) =>
  api.get<UserBundleItemsResp>('/bundles/items', {
    params: {
      user_id: userId,
      page: currentPage + 1,
      per_page: perPage,
      item_ids: preselectedItemIds,
    },
    headers: moneyObjectHeader,
  })

type createBundleSummaryArgs = {
  itemIds: Array<number>
  sellerId: number
}

export const createBundleSummary = ({ itemIds, sellerId }: createBundleSummaryArgs) =>
  api.post<BundleSummaryResp>(
    '/bundle_summary',
    {
      item_ids: itemIds,
      seller_id: sellerId,
    },
    {
      headers: moneyObjectHeader,
    },
  )

export const getBundleOrderReview = ({ itemIds, sellerId }: BundleOrderReviewArgs) =>
  api.post<GetBundleOrderReviewResp>('/bundle_order_review', {
    item_ids: itemIds,
    seller_id: sellerId,
  })

export const createBundleTransaction = ({
  selectedItemIds,
  bundleTrackingId,
  bundleShippingType,
}: CreateBundleTransactionArgs) =>
  api.post<CreateBundleTransactionResp>(
    '/bundles',
    {
      item_ids: selectedItemIds,
      bundle_id: bundleTrackingId,
      bundle_shipping_type: bundleShippingType,
    },
    { headers: moneyObjectHeader },
  )

export const editBundleTransaction = ({
  transactionId,
  selectedItemIds,
  bundleTrackingId,
  bundleShippingType,
}: EditBundleTransactionArgs) =>
  api.put<CreateBundleTransactionResp>(
    `/transactions/${transactionId}/bundle`,
    {
      item_ids: selectedItemIds,
      bundle_id: bundleTrackingId,
      bundle_shipping_type: bundleShippingType,
    },
    { headers: moneyObjectHeader },
  )

export const updateUserBundleDiscounts = (args: UpdateUserBundleDiscountsArgs) =>
  api.put(
    `/users/${args.userId}/multitier_bundle_discount`,
    updateUserBundleDiscountsArgsToParams(args),
  )

export const getSortedSavedSearches = (userId: number) =>
  api.get<GetSortedSavedSearchesResp>(`/users/${userId}/searches/sorted`, {
    headers: moneyObjectHeader,
  })

export const getSavedSearch = ({ userId, id }: GetSavedSearchArgs) =>
  api.get<GetSavedSearchResp>(`/users/${userId}/searches/${id}`, {
    headers: moneyObjectHeader,
  })

export const createSavedSearch = ({ userId, search }: CreateSavedSearchArgs) =>
  api.post<SavedSearchResp>(
    `/users/${userId}/searches`,
    {
      search,
    },
    { headers: moneyObjectHeader },
  )

export const updateSavedSearch = ({
  id,
  userId,
  search,
  keepLastVisitTime,
}: UpdateSavedSearchArgs) =>
  api.put<SavedSearchResp>(
    `/users/${userId}/searches/${id}`,
    {
      search,
      keep_last_visit_time: !!keepLastVisitTime,
    },
    { headers: moneyObjectHeader },
  )

export const getSearchSuggestions = ({ query }: GetSearchSuggestionsArgs) =>
  api.get<SearchSuggestionsResp>('/search_suggestions', {
    params: { query },
  })

export const getInboxConversations = ({ currentPage, perPage }: GetInboxConversationsArgs) =>
  api.get<InboxConversationsResp>('/inbox', {
    params: omitBy(
      {
        page: currentPage,
        per_page: perPage,
      },
      isNil,
    ),
  })

export const getInboxListItem = ({ conversationId }: GetInboxListItemArgs) =>
  api.get<InboxListItemResp>(`/inbox/${conversationId}`)

export const setConversationAsRead = ({ conversationId }: SetConversationAsReadArgs) =>
  api.put<ApiResponse>(`/conversations/${conversationId}/mark_as_read`)

export const translateConversation = ({ conversationId, translate }: TranslateConversationArgs) =>
  api.put<TranslateConversationResp>(`/conversations/${conversationId}/translation`, {
    translate,
  })

export const getUploadAnotherItemTip = (itemId: number) =>
  api.get<UploadAnotherItemTipResp>('/item_upload/upload_another_item_tip', {
    params: { item_id: itemId },
  })

export const getBanners = ({ disableCache = false }: GetBannersArgs = {}) =>
  api.get<BannersResp>('/banners', {
    headers: disableCache ? noCacheHeader : {},
  })

export const getSellerBadges = () => api.get<SellerBadgesResp>('/seller_badges')

export const getSellerBadgesList = (userId: number) =>
  api.get<SellerBadgesListResp>(`/seller_badges_list/${userId}`)

export const getReserveItemDetails = ({ id }: GetReserveItemDetailsArgs) =>
  api.get<ReserveItemDetailsResp>(`/reserve_item_details/${id}`)

export const getMarkAsSoldItemDetails = ({ id }: GetMarkAsSoldItemDetailsArgs) =>
  api.get<MarkAsSoldItemDetailsResp>(`/mark_as_sold_item_details/${id}`)

export const getItemsByPluginName = ({ itemId, name }: GetItemsByPluginNameArgs) =>
  api.get<ItemsByPluginNameResponse>(`/items/${itemId}/plugins/items`, {
    params: { name },
  })

export const getItemEdit = ({ id }: GetItemEditArgs) =>
  api.get<ItemEditResp>(`/item_upload/items/${id}`, { headers: multipleSizeGroupsHeader })

export const getPackageSizes = ({ catalogId, itemId }: GetPackageSizesArgs) =>
  api.get<PackageSizesResp>(`/catalogs/${catalogId}/package_sizes`, {
    params: {
      item_id: itemId,
    },
  })

export const getPackageSizesSuggestion = ({
  item,
  uploadSessionId,
}: GetPackageSizesSuggestionArgs) =>
  api.post<GetPackageSizesSuggestionResp>('package_sizes/suggestion', {
    item,
    session_id: uploadSessionId,
  })

export const getCustomShipmentConfig = () =>
  api.get<CustomShipmentConfigResp>('/configurations/custom_shipment', {
    headers: moneyObjectHeader,
  })

export const getTransactionPackageSizes = ({ transactionId }: GetTransactionPackageSizesArgs) =>
  api.get<PackageSizesResp>(`/transactions/${transactionId}/package_sizes`)

export const selectTransactionPackageSize = ({
  transactionId,
  packageSizeId,
  customShipmentPrice,
}: SelectTransactionPackageSizeArgs) =>
  api.put(`/transactions/${transactionId}/package_size`, {
    package_size_id: packageSizeId,
    custom_shipment_price: customShipmentPrice,
  })

export const getLanguages = () => api.get<GetLanguagesResp>('/languages')

export const getCountryLanguages = (countryId: number) =>
  api.get<GetCountryLanguagesResp>(`countries/${countryId}/languages`)

export const updateUserLanguage = ({ userId, locale }: UpdateUserLanguageArgs) =>
  api.put(`users/${userId}/language_settings/?locale=${locale}`)

export const postReply = ({
  conversationId,
  body,
  photoTempUuids,
  isPersonalDataSharingCheckSkipped,
}: PostReplyArgs) =>
  api.post<ConversationResp, PostReplyNewError>(`conversations/${conversationId}/replies`, {
    reply: {
      body,
      photo_temp_uuids: photoTempUuids,
      is_personal_data_sharing_check_skipped: isPersonalDataSharingCheckSkipped,
    },
  })

export const createItemConversationThread = ({
  itemId,
  initiator,
  receiverId,
}: CreateItemConversationArgs) =>
  api.post<CreateItemConversationResp>('/conversations', {
    initiator,
    item_id: itemId,
    opposite_user_id: receiverId,
  })

export const toggleUserFavourite = ({ entityId }: ToggleUserFavouriteArgs) =>
  api.post('/user_favourites/toggle', {
    type: 'item',
    user_favourites: [entityId],
  })

export const toggleBrandFollow = ({ brandId }: ToggleBrandFollowArgs) =>
  api.post<ToggleBrandFollowResp>('/brands/toggle_follow', {
    brand_id: brandId,
  })

export const toggleUserFollow = ({ userId }: ToggleUserFollowArgs) =>
  api.post<ToggleUserFollowResp>('/followed_users/toggle', { user_id: userId })

export const getFaqEntries = () => api.get<FaqEntriesResp>('/faq_entries')

export const getFaqNotLoggedInEntries = () =>
  api.get<FaqNotLoggedInEntriesResp>('/faq_entries/not_logged_in')

export const getFaqEntryByType = (code: FaqEntryType) =>
  api.get<FaqEntryResp>(`/faq_entries/lookup?code=${code}`)

export const getFaqEntryById = (id: string) => api.get<FaqEntryResp, unknown>(`/faq_entries/${id}`)

export const searchFaqEntries = (searchText: string) =>
  api.get<FaqEntriesSearchResp, unknown>(`/faq_entries/search?query=${searchText}`)

export const getInfoBox = () => api.get<InfoBoxResp>('faq_entries/faq_infobox')

export const requestEmailChange = (userId: number) =>
  api.put(`/users/${userId}/request_email_change`)

export const getUserFeedback = ({
  userId,
  filter,
  page,
  perPage,
  feedbackFilterType,
}: GetUserFeedbackArgs) =>
  api.get<FeedbackListResp>('/user_feedbacks', {
    params: omitBy(
      {
        user_id: userId,
        filter,
        page,
        per_page: perPage,
        by: feedbackFilterType,
      },
      isNil,
    ),
  })

export const deleteFeedback = ({ feedbackId }: DeleteFeedbackArgs) =>
  api.delete(`/user_feedbacks/${feedbackId}`)

export const editFeedbackComment = ({ feedbackId, comment }: EditFeedbackCommentArgs) =>
  api.put<FeedbackCommentResp>(`/user_feedbacks/${feedbackId}/comment`, {
    comment,
  })

export const deleteFeedbackComment = ({ feedbackId }: DeleteFeedbackCommentArgs) =>
  api.put<FeedbackCommentResp>(`/user_feedbacks/${feedbackId}/comment`, { comment: null })

export const getFeedback = ({ feedbackId, localize }: GetFeedbackArgs) =>
  api.get<FeedbackResp>(`/user_feedbacks/${feedbackId}`, {
    params: { localize },
  })

export const createUserFeedback = ({
  userId,
  feedback,
  rating,
  transactionId,
}: CreateFeedbackArgs) =>
  api.post<FeedbackResp>('/user_feedbacks', {
    user_id: userId,
    feedback,
    rating,
    transaction_id: transactionId,
  })

export const editUserFeedback = ({
  feedbackId,
  feedback,
  rating,
  feedbackRate,
}: EditUserFeedbackArgs) =>
  api.patch<FeedbackResp>(`/user_feedbacks/${feedbackId}`, {
    feedback,
    rating,
    feedback_rate: feedbackRate,
  })

export const changeEmail = ({ id, email, code, fingerprint }: ChangeEmailArgs) =>
  api.put(`/users/${id}/save_email`, { email, code, fingerprint })

export const getUserNotifications = ({ page, perPage }: GetUserNotificationsArgs) =>
  api.get<GetUserNotificationsResp>('/notifications', {
    params: {
      page,
      per_page: perPage,
    },
  })

export const sendTwoFactorCode = ({ userId, id, code, fingerprint }: TwoFactorSubmitArgs) =>
  api.put(`/users/${userId}/user_2fa/${id}`, {
    code,
    fingerprint,
  })

export const resendTwoFactorCode = ({ userId, id, code, fingerprint }: TwoFactorSubmitArgs) =>
  api.post<TwoFactorResendResp>(`/users/${userId}/user_2fa/${id}/resend`, {
    code,
    fingerprint,
  })

export const resendLoginTwoFactorCode = ({
  controlCode,
  code,
  fingerprint,
}: TwoFactorLoginSubmitArgs) =>
  api.post<TwoFactorResendResp>(`/user_login_2fa/${controlCode}/resend`, {
    code,
    fingerprint,
  })

export const getPhotos = ({ type, photoEntryId, tempUuid }: GetPhotosArgs) =>
  api.get<GetPhotosResp>('/photos', {
    params: { type, photo_entry_id: photoEntryId, temp_uuid: tempUuid },
  })

export const createPhoto = (photo: FormData) => api.post<CreatePhotoResp>('/photos', photo)

export const setBannerAsSeen = ({ type, name }: SetBannerAsSeenArgs) =>
  api.put(`/banners/${type}/seen`, { name })

export const changeExistingEmail = ({ email, userId }: ChangeExistingEmailArgs) =>
  api.put(`/users/${userId}/change_email`, { email })

export const getCreditCards = () => api.get<GetCreditCardsResp>('/payments/credit_cards')

export const deleteCreditCard = ({ cardId }: CreditCardArgs) =>
  api.delete(`/payments/credit_cards/${cardId}`)

export const deletePhotoSuspicion = (photoId: number) =>
  api.delete(`/user_msg_photos/${photoId}/suspicion`)

export const getCheckoutConfiguration = () =>
  api.get<CheckoutConfigurationResp>('/configurations/checkout')

export const getFavouriteItems = ({ page, perPage, userId }: GetFavouriteItemsArgs) =>
  api.get<FavouriteItemsResp>(`/users/${userId}/items/favourites`, {
    params: {
      page,
      per_page: perPage,
      return_minimised_item_dto: 1,
      return_sold_as_boolean: true,
    },
    headers: moneyObjectHeader,
  })

export const getHomepageBlock = (params: GetHomepageBlockArgs) =>
  api.get<HomepageBlockResp>('homepage/block', {
    params,
  })

export const updateCurrentUserRealName = ({ realName, target }: UpdateCurrentUserRealNameArgs) =>
  api.put('/users/real_name', {
    user: {
      real_name: realName,
    },
    target,
  })

export const getOfflineVerificationEligibilityCriteria = (catalogId: string) =>
  api.get<GetOfflineVerificationEligibiltyCriteriaResp>(
    `/offline_verification/criteria/${catalogId}`,
  )

export const offlineVerificationEligibilityCheck = (
  itemAttributes: Array<{
    field_name: string
    value: string | number | Array<number> | Record<string, number>
  }>,
) =>
  api.post<OfflineVerificationElgibilityCheckResp>('/offline_verification/eligibility', {
    item_attributes: itemAttributes,
  })

export const getIsSecondDayLister = () =>
  api.get<GetTrackerAttributesResp>('/tracker_attributes', {
    params: {
      type: 'second_day_lister',
    },
  })

export const getIsPreviousLister = () =>
  api.get<GetTrackerAttributesResp>('/tracker_attributes', {
    params: {
      type: 'previous_lister',
    },
  })

export const getItemPriceSuggestions = ({
  brandId,
  statusId,
  catalogId,
  temporaryPhotoId,
}: GetItemPriceSuggestionsArgs) =>
  api.get<PriceSuggestionsResp>('/item_price_suggestions', {
    params: {
      brand_id: brandId,
      status_id: statusId,
      catalog_id: catalogId,
      temporary_photo_id: temporaryPhotoId,
    },
  })

export const getBumpOptions = ({ itemIds }: GetBumpOptionsArgs) =>
  api.get<BumpOptionResp>('/push_ups/options', {
    params: { item_ids: itemIds },
    headers: moneyObjectHeader,
  })

export const prepareBumpOrder = (
  itemIds: Array<number>,
  effectiveDays: number,
  international?: boolean,
) =>
  api.post<PrepareBumpOrderResp>(
    '/push_ups/orders',
    {
      item_ids: itemIds,
      effective_days: effectiveDays,
      international,
    },
    { headers: moneyObjectHeader },
  )

export const getBumpMinimumPrice = () =>
  api.get<BumpMinimumPriceResp>('/push_ups/minimum_price', {
    headers: moneyObjectHeader,
  })

export const getUserSizes = () => api.get<UserSizesResp>('/user_sizes')

export const updateUserSizes = (sizeIds: Array<number>) =>
  api.post('/user_sizes', {
    size_ids: sizeIds,
  })

export const getSimilarSoldItems = ({
  brandId,
  statusId,
  catalogId,
  temporaryPhotoId,
  uploadSessionId,
}: GetSimilarSoldItemsArgs) =>
  api.get<GetSimilarSoldItemsResp>('/item_upload/items/similar_sold_items', {
    params: {
      brand_id: brandId,
      status_id: statusId,
      catalog_id: catalogId,
      temporary_photo_id: temporaryPhotoId,
      upload_session_id: uploadSessionId,
    },
  })

export const getTransaction = ({ id }: GetTransactionArgs) =>
  api.get<GetTransactionResp>(`/transactions/${id}`, { headers: moneyObjectHeader })

export const getCheckoutData = (transactionId: number) =>
  api.get<UpdateCheckoutDataResp>(`/transactions/${transactionId}/checkout`, {
    headers: moneyObjectHeader,
  })

export const updateCheckoutData = (transactionId: number, args: UpdateCheckoutDataArgs | null) =>
  api.put<UpdateCheckoutDataResp>(
    `/transactions/${transactionId}/checkout`,
    args && updateCheckoutDataArgsToParams(args),
    { headers: moneyObjectHeader },
  )

export const initiateSingleCheckout = ({ id, type }: InitiateSingleCheckoutArgs) =>
  api.post<SingleCheckoutResp>('/purchases/checkout/build', {
    purchase_items: [{ id: Number(id), type }],
  })

export const updateSingleCheckoutData = (id: string, args: UpdateSingleCheckoutArgs | undefined) =>
  api.put<SingleCheckoutResp>(
    `/purchases/${id}/checkout`,
    args && updateSingleCheckoutDataArgsToParams(args),
  )

export const refreshSingleCheckoutPurchase = (id: string) =>
  api.put<SingleCheckoutResp>(`/purchases/${id}/checkout`, { components: [] })

export const initiateSingleCheckoutPayment = (
  id: string,
  args: InitiateSingleCheckoutPaymentArgs,
) =>
  api.post<SingleCheckoutPaymentResp>(
    `purchases/${id}/checkout/payment`,
    singleCheckoutPaymentInitiationArgsToParams(args),
  )

export const continueSingleCheckoutPayment = (
  id: string,
  args: ContinueSingleCheckoutPaymentArgs,
) =>
  api.post<SingleCheckoutPaymentResp>(
    `purchases/${id}/checkout/payment/continue`,
    singleCheckoutPaymentContinueArgsToParams(args),
  )

export const getSingleCheckoutPayment = (id: string) =>
  api.get<SingleCheckoutPaymentResp>(`purchases/${id}/checkout/payment`)

export const updateSingleCheckoutPaymentData = (
  id: string,
  args: UpdateSingleCheckoutPaymentDataArgs,
) =>
  api.put<SingleCheckoutPaymentResp>(
    `purchases/${id}/checkout/payment`,
    escrowPaymentDataUpdateArgsToParams(args),
  )

export const updateSingleCheckoutPaymentFailure = (id: string) =>
  api.post<ApiResponse>(`purchases/${id}/checkout/payment/failure`)

export const getPaymentMethods = (id: string) =>
  api.get<PayInMethodsResp>(`/purchases/${id}/checkout/payment_methods`)

export const getEscrowPayment = (transactionId: number) =>
  api.get<EscrowPaymentResp>(`/transactions/${transactionId}/checkout/payment`)

export const initiateEscrowPayment = (transactionId: number, args: InitiateEscrowPaymentArgs) =>
  api.post<EscrowPaymentResp>(
    `/transactions/${transactionId}/checkout/payment`,
    escrowPaymentInitiationArgsToParams(args),
  )

export const updateEscrowPaymentData = (transactionId: number, args: UpdateEscrowPaymentDataArgs) =>
  api.put<EscrowPaymentResp>(
    `/transactions/${transactionId}/checkout/payment`,
    escrowPaymentDataUpdateArgsToParams(args),
  )

export const getBookDetails = ({ isbn }: GetBookDetailsArgs) =>
  api.get<BookDetailsResp>('/book_details', {
    params: {
      isbn,
    },
  })

export const resolveComplaint = (id: string) => api.post(`/complaints/${id}/resolve`)

export const getUserItemFacets = ({ userId }: GetUserItemFacetsArgs) =>
  api.get<ItemFacetsResp>(`/users/${userId}/item_facets`)

export const reuploadItem = (id: number) =>
  api.post<ReuploadItemResp>(`/transactions/${id}/reupload`)

export const confirmCancellationAgreement = (transactionId: number) =>
  api.post(`/transactions/${transactionId}/cancellation_agreement/confirm`)

export const declineCancellationAgreement = (transactionId: number) =>
  api.post(`/transactions/${transactionId}/cancellation_agreement/decline`)

export const markAsDelivered = (shipmentId: number) =>
  api.put(`/shipments/${shipmentId}/mark_as_delivered`)

export const getOfflineVerificationFeedback = (transactionId: number) =>
  api.get<GetOfflineVerificationFeedbackResp>(
    `/transactions/${transactionId}/verification_feedbacks`,
  )

export const sendOfflineVerificationFeedback = (args: SendOfflineVerificationFeedbackArgs) =>
  api.put(
    `/transactions/${args.transactionId}/verification_feedbacks`,
    sendOfflineVerificationFeedbackArgsToParams(args),
  )

export const getCurrentUser = () => api.get<UserResp>(CURRENT_USER_API_ENDPOINT)

export const updateCurrentUser = (args: UpdateCurrentUserArgs) =>
  api.put<UserResp>(CURRENT_USER_API_ENDPOINT, updateCurrentUserArgsToParams(args))

export const updateCurrentUserSettings = (args: UpdateCurrentUserSettingsArgs) =>
  api.put<UserResp>(CURRENT_USER_API_ENDPOINT, {
    user: {
      user_settings: updateCurrentUserSettingsArgsToParams(args),
    },
  })

export const updateBusinessAccountProfileDetails = (
  args: UpdateBusinessAccountProfileDetailsArgs,
) =>
  api.put<BusinessAccountResp>(
    `/business_accounts/${args.businessAccountId}/profile`,
    updateBusinessAccountProfileDetailsArgsToParams(args),
  )

export const updateBusinessAccountRepresentativeDetails = ({
  identityDocumentExpirationDate,
  email,
}: UpdateBusinessAccountRepresentativeDetailsArgs) =>
  api.put('/business_accounts_dsa/representatives', {
    identity_document_expiration_date: identityDocumentExpirationDate,
    email,
  })

export const getBusinessAccountRepresentativeDetails = () =>
  api.get<GetBusinessAccountRepresentativeDetailsResp>('/business_accounts_dsa/representatives')

export const updateEprUin = ({ uinId, uinNumber }: UpdateEprUinArgs) =>
  api.put(`/business_accounts/epr_uins/${uinId}`, {
    epr_uin: {
      uin: uinNumber,
    },
  })

export const deleteEprUin = (uinId: number | string) =>
  api.delete(`/business_accounts/epr_uins/${uinId}`)

export const markAsShipped = ({ transactionId, proofPhotoUuid }: MarkAsShippedArgs) =>
  api.put(`/transactions/${transactionId}/shipment/mark_as_shipped`, {
    proof_photo_uuid: proofPhotoUuid,
  })

export const partiesHaveMet = (transactionId: number) =>
  api.put(`/transactions/${transactionId}/shipment/parties_have_met`)

export const getSddEducationInfo = () =>
  api.get<KycEducationInfoResp>('/payments/identity_simplified/education')

export const getSddFormConfiguration = () =>
  api.get<SddFormConfigurationResp>('/payments/identity_simplified')

export const getSddStatus = () => api.get<SddStatusResp>('payments/identity_simplified/status')

export const submitSimplifiedPaymentsIdentity = (args: SubmitSimplifiedPaymentsIdentityArgs) =>
  api.post<SddMethodsConfigurationResp>('/payments/identity_simplified', {
    payments_identity_simplified: args,
  })

export const submitVerificationMethod = (id: string) =>
  api.post<SddMethodSelectionResp>('payments/identity_simplified/start_verification', {
    method: { id },
  })

export const getPaymentsIdentityFormConfiguration = () =>
  api.get<PaymentsIdentityFormConfigurationResp>('/payments/identity/edit')

export const getBusinessAccountsTermsOfUseContent = () =>
  api.get<BusinessAccountsTermsOfUseResp>('/pro_terms_of_use')

export const getPrivateBetaTermsOfUseContent = () =>
  api.get<BusinessAccountsTermsOfUseResp>('/private_beta_terms_of_use')

export const getProTermsAndConditionsContent = () =>
  api.get<BusinessAccountsTermsOfUseResp>('/pro_terms_and_conditions')

export const getProIntegrationsTermsAndConditionsContent = () =>
  api.get<BusinessAccountsTermsOfUseResp>('/pro_integrations_terms_and_conditions')

export const createDigitalProductPassportDraftItem = (dppRef: string) =>
  api.post<CreateDigitalProductPassportDraftItemResp>(`/dpp/items/${dppRef}/draft`)

export const deleteConversationSuspicion = (threadId: number | string) =>
  api.delete(`/msg_threads/${threadId}/suspicion`)

export const getComplaint = (id: string) => api.get<ComplaintResp>(`/complaints/${id}`)

export const getSnadComplaint = (transactionId: number) =>
  api.get<SnadComplaintResp>('/complaints/snad', {
    params: { transaction_id: transactionId },
  })

export const getRefundOfferCreationDetails = (complaintId: number) =>
  api.get<RefundOfferCreationDetailsDto>(`/complaints/${complaintId}/refund_offer_creation_details`)

export const getRefundOfferId = (complaintId: number) =>
  api.get<RefundOfferIdDto>(`/complaints/${complaintId}/refund_offer_id`)

export const sendRefundOffer = ({ complaintId, refundOffer }: SendRefundOfferArgs) =>
  api.post<SendRefundOfferResp>('/refund_offers', {
    complaint_id: complaintId,
    refund_offer: { amount: refundOffer.amount, currency_code: refundOffer.currencyCode },
  })

export const handleRefundOffer = ({ refundOfferId, action }: HandleRefundOfferAction) =>
  api.post(`/refund_offers/${refundOfferId}/${action}`)

export const getRefundOfferEstimateDetails = (refundOfferId: number) =>
  api.get<GetRefundOfferEstimateDetailsResp>(`/refund_offers/${refundOfferId}/refund_estimate`)

export const getSecuritySettings = (userId: number) =>
  api.get<SecuritySettingsResp>(`/users/${userId}/security`)

export const setVoluntary2FA = (userId: number, isVoluntary2FA: boolean) =>
  api.put(`users/${userId}/security/voluntary_2fa`, {
    is_voluntary_2fa: isVoluntary2FA,
  })

export const createUser2FA = (args: CreateUser2FAArgs) =>
  api.post<CreateUser2FAResp>(`/users/${args.userId}/user_2fa`, {
    entity_id: args.entityId,
    type: args.entityType,
    fingerprint: args.fingerprint,
  })

export const submitPaymentsIdentity = (args: SubmitPaymentsIdentityArgs) =>
  api.put<PaymentsIdentityFormConfigurationResp>('/payments/identity', {
    payments_identity: submitPaymentsIdentityArgsToParams(args),
  })

export const addAddress = (args: AddUserAddressArgs) =>
  api.post<UserAddressResp>('/user_addresses', {
    user_address: addUserAddressArgsToParams(args),
    is_single_checkout: args.isSingleCheckout,
    ...(args.skipShippingCallback && { skip_shipping_callback: args.skipShippingCallback }),
  })

export const getTaxAddress = () => api.get<GetTaxAddressResp>('/user_addresses/missing_info')

export const addShippingAddress = (args: AddShippingAddressArgs) =>
  api.post<UserAddressResp>(`/transactions/${args.transactionId}/shipping_destination`, {
    user_address: addShippingAddressArgsToParams(args),
  })

export const getDefaultBillingAddress = () =>
  api.get<UserAddressResp>('/user_addresses/default_billing_address')

export const addShippingOrderAddress = (args: AddShippingOrderAddressArgs) =>
  api.post(`/shipping_orders/${args.shippingOrderId}/shipping_address`, {
    ...addShippingOrderAddressArgsToParams(args),
  })

export const addUserAddressesMissingInfo = (user_address: AddUserAddressesMissingInfoArgs) =>
  api.post<UserAddressResp>('/user_addresses/missing_info', {
    user_address,
  })

export const getDefaultShippingAddress = () =>
  api.get<DefaultShippingAddressResp>('/user_addresses/default_shipping_address')

export const getInfoBanner = ({ screen, params }: GetInfoBannerArgs) =>
  api.get<InfoBannerResp>(`/info_banners/${screen}`, { params })

export const createPhoneNumber = (userId: number, phoneNumber: string) =>
  api.post<NewPhoneNumberResp>(`/users/${userId}/phone_number`, {
    phone_number: {
      number: phoneNumber,
    },
  })

export const changePhoneNumber = ({
  userId,
  currentNumber,
  newNumber,
  fingerprint,
}: ChangePhoneNumberArgs) =>
  api.post<NewPhoneNumberResp>(`/users/${userId}/phone_number/change`, {
    current_phone: currentNumber,
    new_phone: newNumber,
    fingerprint,
  })

export const verifyPhoneNumber = ({
  userId,
  code,
  phoneNumber,
  fingerprint,
}: {
  userId: number
  code: string
  phoneNumber: string
  fingerprint: string
}) =>
  api.put(`/users/${userId}/phone_number/verify`, {
    code,
    phone_number: phoneNumber,
    fingerprint,
  })

export const getReferralRegistrations = () =>
  api.get<ReferralRegistrationsResp>('/referrals/registrations')

export const getReferralInviteInstructions = () =>
  api.get<ReferralInviteInstructionsResp>('/referrals/invite_instructions')

export const getReferralShare = () => api.get<ReferralShareResp>('/referrals/share/new')

export const getCountryBounds = (countryId: number) =>
  api.get<CountryBoundsResponse>(`/countries/${countryId}/bounds`)

export const getWalletBalance = (userId: number) =>
  api.get<WalletBalanceResponse>(`users/${userId}/balance`, { headers: moneyObjectHeader })

export const getItemSuggestions = ({
  title,
  description,
  catalogId,
  photoIds,
  uploadSessionId,
}: GetItemSuggestionsArgs) =>
  api.get<ItemSuggestionsResponse>('items/suggestions', {
    params: {
      title,
      description,
      catalog_id: catalogId,
      photo_ids: photoIds.join(','),
      upload_session_id: uploadSessionId,
    },
    headers: multipleSizeGroupsHeader,
  })

export const getTrackingJourneySummary = ({ transactionId }: GetTrackingJourneySummaryArgs) =>
  api.get<TrackingJourneySummaryResponse>(`transactions/${transactionId}/shipment/journey_summary`)

export const getPickUpParcelCode = ({ transactionId }: GetPickUpParcelCodeArgs) =>
  api.get<PickUpParcelCodeResponse>(`transactions/${transactionId}/shipment/parcel_collection_code`)

export const getShipmentInstructions = ({ transactionId }: GetShipmentInstructionsArgs) =>
  api.get<ShipmentInstructionsResponse>(`transactions/${transactionId}/shipment_instructions`, {
    headers: moneyObjectHeader,
  })

export const getPostalCodeConfiguration = ({ code, countryId }: PostCodeArgs) =>
  api.get<PostCodeResponse>(`postal_codes/${code}`, {
    params: {
      country_id: countryId,
    },
  })

export const orderShippingLabel = ({
  sellerAddressId,
  transactionId,
  pickUpDate,
  digitalLabelType,
  dropOffTypeKey,
  selectedDropOffPointType,
}: OrderShippingLabelArgs) =>
  api.put<OrderShippingLabelResp>(`transactions/${transactionId}/shipment/order`, {
    seller_address_id: sellerAddressId,
    pick_up_date: pickUpDate,
    digital_label_type: digitalLabelType,
    drop_off_type_key: dropOffTypeKey,
    drop_off_type: selectedDropOffPointType,
  })

export const linkFacebook = (accessToken: string) =>
  api.post('facebook_user/link', {
    fb_access_token: accessToken,
  })

export const unlinkFacebook = () => api.delete('facebook_user')

export const linkGoogle = (idToken: string) =>
  api.post('google_user/link', {
    id_token: idToken,
  })

export const unlinkGoogle = () => api.delete('google_user')

export const createPaymentsAccount = (account: CreatePaymentsAccountArgs) =>
  api.post<PaymentsAccountResp>(
    `/users/${account.userId}/payments_account`,
    createPaymentsAccountArgsToParams(account),
  )

export const startOnboarding = (userId: number) =>
  api.put(`/users/${userId}/payments_account/start_onboarding`)

export const getPaymentsAccount = (userId: number) =>
  api.get<PaymentsAccountResp>(`/users/${userId}/payments_account`)

export const getCurrentWalletInvoices = () =>
  api.get<WalletInvoicesResp>('/wallet/invoices/current', {
    headers: moneyObjectHeader,
  })

export const getHistoricalWalletInvoices = (year: number, month: number, page = 1) =>
  api.get<WalletInvoicesResp>(`/wallet/invoices/${year}/${month}`, {
    params: { page },
    headers: moneyObjectHeader,
  })

export const createPayout = (args: CreatePayoutArgs) =>
  api.post<CreatePayoutResp>(`/users/${args.userId}/payouts`, createPayoutArgsToParams(args))

export const getPayoutConfiguration = (userId: number) =>
  api.get<GetPayoutConfigurationResp>(`/users/${userId}/payouts/new`)

export const getPayout = (userId: number, payoutId: number) =>
  api.get<PayoutResp>(`/users/${userId}/payouts/${payoutId}`)

export const getRefund = (transactionId: number) =>
  api.get<RefundResp>(`/transactions/${transactionId}/refund`)

export const getBankAccountRegistration = () =>
  api.post<BankAccountRegistration>('/payments/bank_account_registrations/prepare')

export const getBankAccounts = () => api.get<BankAccountsResp>('/bank_accounts')

export const addBankAccount = (bankAccount: AddBankAccountArgs, fingerprint: string) =>
  api.post<AddBankAccountResponse>('/bank_accounts', {
    bank_account: addBankAccountArgsToParams(bankAccount),
    fingerprint,
  })

export const deleteBankAccount = ({
  bankAccountId,
  fingerprint,
}: {
  bankAccountId: number
  fingerprint: string
}) => api.delete(`/bank_accounts/${bankAccountId}?fingerprint=${fingerprint}`)

export const validateUser = ({ user }: ValidateUserArgs) => api.post('/users/validations', { user })

export const validateBusinessUboAddress = (business_ubo_address: BusinessAddressDto) =>
  api.post<BusinessUboAddressResp>('/user_addresses/validate_business_ubo_address', {
    business_ubo_address,
  })

export const validateBusinessRegistrationDsa = (registration: BusinessRegistrationDto) =>
  api.post('/business_accounts_dsa/validate', registration)

export const submitBusinessRegistrationDsa = (registration: BusinessRegistrationDto) =>
  api.post('/business_accounts_dsa', registration)

export const deleteBusinessRegistration = (userId: number) =>
  api.delete(`/business_accounts/${userId}`)

export const addEprInfo = (eprInfo: EprInfoDto) => api.post('/business_accounts/epr_uins', eprInfo)

export const getEprCategories = () =>
  api.get<EprCategoriesResp>('/business_accounts/epr_uins/categories')

export const getEprCountries = () =>
  api.get<EprCountriesResp>('/business_accounts/epr_uins/allowed_countries')

export const getEprUinList = () => api.get<EprUinListResp>('/business_accounts/epr_uins')

export const getBusinessAccountPolicies = ({ businessAccountId }: GetBusinessAccountPoliciesArgs) =>
  api.get<BusinessAccountPoliciesResp>(`/business_accounts/${businessAccountId}/terms`)

export const setBusinessAccountPolicies = (args: SetBusinessAccountPoliciesArgs) =>
  api.post<BusinessAccountPoliciesResp>(
    `/business_accounts/${args.businessAccountId}/terms`,
    setBusinessAccountPoliciesArgsToParams(args),
  )

export const getBusinessAccountAddresses = ({
  businessAccountId,
}: GetBusinessAccountAddressesArgs) =>
  api.get<BusinessAccountAddressesResp>(`/business_accounts/${businessAccountId}/addresses`)

export const setBusinessAccountAddresses = ({
  businessAccountId,
  ...args
}: SetBusinessAccountAddressesArgs) =>
  api.post<BusinessAccountAddressesResp>(
    `/business_accounts/${businessAccountId}/addresses`,
    setBusinessAccountAddressesArgsToParams(args),
  )

export const getWorldCountries = () => api.get<WorldCountriesResp>('/world_countries')

export const dismissBanner = (type: keyof BannersDto) => api.post(`/banners/${type}/dismissed`)

export const getItemUploadConditions = ({ catalogId }: ItemUploadConditionsArgs) =>
  api.get<ItemUploadConditionsResp>('/item_upload/conditions', {
    params: { catalog_id: catalogId },
  })

export const getFeedbackProblemOptions = (userId: number, conversationId: number | string) =>
  api.get<FeedbackProblemOptionsResp>(
    `/users/${userId}/msg_threads/${conversationId}/problem_options`,
  )

export const sendFeedbackProblemFeedback = (args: SendFeedbackProblemFeedbackArgs) =>
  api.post<ApiResponse>(
    `/users/${args.userId}/msg_threads/${args.conversationId}/problem_options`,
    {
      problem_option_id: args.problemOptionId,
      message: args.message,
      help_center_session_id: args.helpCenterSessionId,
    },
  )

export const getConversationFeedbackRatingOptions = (
  userId: number,
  conversationId: number | string,
) =>
  api.get<FeedbackRatingOptionsResp>(
    `/users/${userId}/msg_threads/${conversationId}/feedback_ratings`,
  )

export const updateFeedbackRatingOptions = (
  userId: number,
  conversationId: number | string,
  args: UpdateFeedbackRatingOptionsArgs,
) =>
  api.put<ApiResponse>(
    `/users/${userId}/msg_threads/${conversationId}/feedback_ratings`,
    updateFeedbackRatingOptionsArgsToParams(args),
  )

export const getPayInMethods = (userId: number) =>
  api.get<PayInMethodsResp>(`/users/${userId}/pay_in_methods`)

export const getTransactionPayInMethods = (transactionId: number) =>
  api.get<PayInMethodsResp>(`/transactions/${transactionId}/pay_in_methods`)

export const getBusinessAccountInvoiceInstructions = ({
  transactionId,
}: GetBusinessAccountInvoiceInstructionsArgs) =>
  api.get<BusinessAccountInvoiceInstructionsResp>(
    `transactions/${transactionId}/business_account_invoice_instructions`,
  )

export const prepareCreditCardRegistration = ({
  name,
  cardBrand,
}: PrepareCreditCardRegistrationArgs) =>
  api.post<PrepareCreditCardRegistrationResp>('/payments/credit_card_registrations/prepare', {
    credit_card_registration: {
      name,
      card_brand: cardBrand,
    },
  })

export const getUserPermissions = (userId: number) =>
  api.get<UserPermissionsResp>(`/users/${userId}/permissions`)

export const registerCreditCard = (args: RegisterCreditCardArgs) =>
  api.post<RegisterCreditCardResp>('/payments/credit_cards', registerCreditCardArgsToParams(args))

export const authenticateCreditCard = (args: AuthenticateCreditCardArgs) =>
  api.put<RegisterCreditCardResp>(
    '/payments/credit_cards/authentication_details',
    authenticateCreditCardArgsToParams(args),
  )
export const getCreditCardRegistration = () =>
  api.get<CreditCardRegistrationResp>('/payments/credit_card_registrations/new')

export const getCreditCardRegistrationBrands = (bin: string) =>
  api.get<CreditCardRegistrationBrandsResp>('/payments/credit_card_registrations/brands', {
    params: { bin },
  })

export const getUsers = ({ perPage, searchText, page, order, itemId }: GetUsersArgs) =>
  api.get<UsersResp>('/users', {
    params: {
      page,
      order,
      per_page: perPage,
      search_text: searchText,
      order_by_buyer_for_item: itemId,
    },
  })

export const getFollowedUsers = ({ userId, perPage, page }: GetFollowUsersArgs) =>
  api.get<UsersResp>(`users/${userId}/followed_users`, {
    params: { per_page: perPage, page },
  })

export const deleteConversation = (id: number | string) => api.delete(`/conversations/${id}`)

export async function getRecentUsers(keyword?: string) {
  return api.get<RecentUsersResp>('/help_center/recent_users', {
    params: { keyword },
  })
}

export const getRecentTransactions = (count?: number) =>
  api.get<RecentTransactionsResp>('help_center/recent_transactions', {
    params: { count },
    headers: moneyObjectHeader,
  })

export const getMyOrders = (args: GetMyOrdersArgs) =>
  api.get<MyOrdersResp>('my_orders', {
    params: getMyOrdersArgsToParams(args),
    headers: moneyObjectHeader,
  })

export const getProfileUserInfo = (userId: number) =>
  api.get<ProfileUserInfoResp>(`users/${userId}/profile`)

export const getTransactionCancellationReasons = (id: number) =>
  api.get<TransactionCancellationReasonsResp>(`/transactions/${id}/cancellation_reasons`)

export const cancelTransaction = ({ id, reasonId, userExplanation }: CancelTransactionArgs) =>
  api.put(`/transactions/${id}/cancel`, {
    reason: {
      id: reasonId,
      user_explanation: userExplanation,
    },
  })

export const createSupportTicket = (args: CreateSupportTicketArgs) =>
  api.post<SubmitTicketResp, ResponseError>(
    '/help_center/support_tickets',
    getCreateSupportTicketArgs(args),
  )

export const createComplaint = (args: CreateComplaintArgs) =>
  api.post<SubmitComplaintResp, ResponseError>('/complaints', getCreateComplaintArgs(args))

export const getLiveChatAvailability = (
  transactionId: number,
): Promise<LiveChatAvailabilityResp | ResponseError> =>
  api.get<LiveChatAvailabilityResp, ResponseError>(
    `/help_center/live_chat_status?transaction_id=${transactionId}`,
  )

export const getIsAutomatedSupportFeedbackEnabled = (
  args: GetIsAutomatedSupportFeedbackEnabledArgs,
) =>
  api.get<IsAutomatedResponseFeedbackEnabledResp>(
    `/users/${args.userId}/msg_threads/${args.conversationId}/automated_support_feedback`,
    { params: { survey_version: args.surveyVersion } },
  )

export const submitAutomatedSupportFeedback = (args: SubmitAutomatedSupportFeedbackArgs) =>
  api.post(`/users/${args.userId}/msg_threads/${args.conversationId}/automated_support_feedback`, {
    rating: args.rating,
    help_center_session_id: args.helpCenterSessionId,
    survey_version: args.surveyVersion,
    feedback_for: args.feedbackFor,
    comment: args.comment,
  })

export const requestFurtherHelp = (args: RequestFurtherHelpArgs) =>
  api.post(`/users/${args.userId}/msg_threads/${args.conversationId}/request_further_help`, {
    help_center_session_id: args.helpCenterSessionId,
  })

export const createLiveChatTicket = (args: CreateLiveChatTicketArgs) =>
  api.post<CreateLiveChatTicketResp, ResponseError>(
    '/help_center/live_chat_ticket',
    createLiveChatTicketArgs(args),
  )

export const updateBusinessAccountBankDetails = ({
  bankAccountOwnerName,
  bankAccountNumber,
}: updateBusinessAccountBankDetailsArgs) =>
  api.put('/business_accounts_dsa', {
    bank_account_number: bankAccountNumber,
    bank_account_owner_name: bankAccountOwnerName,
  })

export const updateVatNumber = async ({ vatNumber, countryCode, userId }: updateVatNumberArgs) => {
  return api.put<updateVatNumberResponse, ResponseError>('/business_accounts/update_vat_number', {
    vat_number: vatNumber,
    country_code: countryCode,
    user_id: userId,
  })
}

export const reserveItem = ({ id, buyerId }: ReserveItemArgs) =>
  api.post(`/items/${id}/reservation`, {
    to_whom_id: buyerId,
  })

export const getNotificationSettings = (userId: number, type: 'email' | 'mobile' = 'email') =>
  api.get<NotificationSettingsResp>(`/users/${userId}/notification_settings`, {
    params: { type },
  })

export const updateNotificationSettings = (
  userId: number,
  settings: Record<string, boolean | number>,
) =>
  api.put(`/users/${userId}/notification_settings`, { notification_settings: { email: settings } })

export const convertBusinessWallet = () =>
  api.post<WalletConversionStatusResp, WalletConversionErrorDto>('/business_accounts/conversion')

export const getWalletConversionStatus = () =>
  api.get<WalletConversionStatusResp>('/business_accounts/conversion')

export const getCarrierPreferences = () => api.get<CarrierPreferencesResp>('/carrier_preferences')

export const updateCarrierPreference = ({ carrierCode, enabled }: UpdateCarrierPreferenceArgs) =>
  api.put<CarrierPreferenceResp>('/carrier_preferences', {
    carrier_code: carrierCode,
    enabled,
  })

export const getShippingPreferences = () =>
  api.get<GetShippingPreferencesResp>('/shipping_preferences')

export const updateShippingPreference = ({
  dropOffType,
  carrierCode,
  enabled,
}: UpdateShippingPreferenceArgs) =>
  api.patch<UpdateShippingPreferenceResp>(`/shipping_preferences/${dropOffType}/${carrierCode}`, {
    enabled,
  })

export const getCarrierSettingsFeedbackConfiguration = () =>
  api.get<GetCarrierSettingsFeedbackConfigurationResp>('/carrier_preferences/feedback')

export const getShippingPreferenceFeedbackConfiguration = ({
  dropOffType,
}: GetShippingPreferenceFeedbackConfigurationArgs) =>
  api.get<GetCarrierSettingsFeedbackConfigurationResp>('shipping_preferences/feedback', {
    params: {
      drop_off_type: dropOffType,
    },
  })
export const getDigitalLabel = ({ transactionId }: GetDigitalLabelArgs) =>
  api.get<DigitalLabelResp>(`/transactions/${transactionId}/shipment/digital_label`)

export const getShippingContactConfiguration = () =>
  api.get<ShippingContactConfigurationResp>('/configurations/shipping_contact')

export const saveTransactionShippingContact = (args: SaveTransactionShippingContactArgs) =>
  api.post<ApiResponse>(`/transactions/${args.transactionId}/shipping_contact`, {
    save_for_later: args.saveForLater,
    shipping_contact: omitBy(
      {
        buyer_phone_number: args.buyerPhoneNumber,
        seller_phone_number: args.sellerPhoneNumber,
      },
      isNil,
    ),
  })

export const saveShippingOrderContact = (args: SaveShippingOrderContactArgs) =>
  api.post<ApiResponse>(`/shipping_orders/${args.shippingOrderId}/shipping_contact`, {
    save_for_later: args.saveForLater,
    receiver_phone_number: args.receiverPhoneNumber,
    sender_phone_number: args.senderPhoneNumber,
    phone_number: args.phoneNumber,
  })

export const deleteTransactionShippingContact = (transactionId: number) =>
  api.delete<ApiResponse>(`/transactions/${transactionId}/shipping_contact`)

export const deleteShippingOrderContact = (shippingOrderId: string) =>
  api.delete<ApiResponse>(`/shipping_orders/${shippingOrderId}/shipping_contact`)

export const getOfferRequestOptions = ({ itemPrice, sellerId }: GetOfferRequestOptionsArgs) =>
  api.post<GetOfferRequestOptionsResp>('/offers/request_options', {
    price: {
      amount: itemPrice.amount,
      currency_code: itemPrice.currencyCode,
    },
    seller_id: sellerId,
  })

export const createOfferRequest = ({ price, currency, transactionId }: CreateOfferArgs) =>
  api.post<CreateOfferRequestResp>(`/transactions/${transactionId}/offer_requests`, {
    offer_request: {
      price,
      currency,
    },
  })

export const createOffer = ({ transactionId, price, currency }: CreateOfferArgs) =>
  api.post<CreateOfferResp>(`/transactions/${transactionId}/offers`, {
    offer: {
      price,
      currency,
    },
  })

export const getDataExportStatus = (userId: number) =>
  api.get<DataExportStatusResp>(`/users/${userId}/personal_data_export`)

export const requestDataExport = ({
  userId,
  fingerprint,
}: {
  userId: number
  fingerprint: string
}) => api.post(`/users/${userId}/personal_data_export`, { fingerprint })

export const confirmDataExport = ({ userId, code }: ConfirmDataExportArgs) =>
  api.post(`/users/${userId}/personal_data_export/confirmation`, { code })

export const createAdminAlert = (args: CreateAdminAlertArgs) =>
  api.post(`/users/${args.userId}/admin_alerts`, createAdminAlertArgsToParams(args))

export const getReportReasons = (alertType: AlertType) =>
  api.get<ReportReasonsResp>(`/report_reasons/${alertType}`)

export const acceptOffer = ({ transactionId, offerRequestId }: AcceptOfferArgs) =>
  api.put(`/transactions/${transactionId}/offer_requests/${offerRequestId}/accept`)

export const rejectOffer = ({ transactionId, offerRequestId }: RejectOfferArgs) =>
  api.put(`/transactions/${transactionId}/offer_requests/${offerRequestId}/reject`)

export const sendProductFeedback = (args: SendProductFeedbackArgs) =>
  api.post('/app_feedbacks', { ...args, user_id: args.userId })

export const deleteMessage = ({ conversationId, messageId }: DeleteMessageNewArgs) =>
  api.delete(`conversations/${conversationId}/replies/${messageId}`)

export const deletePhotoMessage = ({
  conversationId,
  messageId,
  photoId,
}: DeletePhotoMessageNewArgs) =>
  api.delete(`/conversations/${conversationId}/replies/${messageId}/photos/${photoId}`)

export const postLikeMessage = ({ conversationId, messageId, reactionType }: PostLikeMessageArgs) =>
  api.post<PostLikeMessageResp>(`conversations/${conversationId}/replies/${messageId}/reactions`, {
    type: reactionType,
  })

export const deleteLikeMessage = ({
  conversationId,
  messageId,
  reactionId,
}: DeleteLikeMessageArgs) =>
  api.delete(`conversations/${conversationId}/replies/${messageId}/reactions/${reactionId}`)

export const postLikeMessagePhoto = ({
  conversationId,
  messageId,
  photoId,
  reactionType,
}: PostLikeMessagePhotoArgs) =>
  api.post<PostLikeMessagePhotoResp>(
    `conversations/${conversationId}/replies/${messageId}/photos/${photoId}/photo_reactions`,
    {
      type: reactionType,
    },
  )

export const deleteLikeMessagePhoto = ({
  conversationId,
  messageId,
  photoId,
  reactionId,
}: DeleteLikeMessagePhotoArgs) =>
  api.delete(
    `conversations/${conversationId}/replies/${messageId}/photos/${photoId}/photo_reactions/${reactionId}`,
  )

export const getKycEducationInfo = () =>
  api.get<KycEducationInfoResp>('/payments/identity/education')

export const getBankStatementEducation = () =>
  api.get<BankStatementEducationResp>('/payments/identity/educations/bank_statement')

export const getKycPhotoTipType = () =>
  api.get<getKycPhotoTipTypeResp>('/payments/identity/educations/photo_tips')

export const markEducationAsRead = (type: EducationType) => api.post('/user/education', { type })

export const getItemPerformance = (itemId: number) =>
  api.get<ItemPerformanceResponse>(`items/${itemId}/performance`)

export const getBrazeEventProperties = ({ eventName, modelId }: GetBrazeEventPropertiesArgs) =>
  api.get<GetBrazeEventPropertiesResp>('/external_crm/event_payload', {
    params: {
      event_name: eventName,
      model_id: modelId,
    },
  })

export const getRequestReturnDetails = (transactionId: number) =>
  api.get<GetRequestReturnDetailsResp>(
    `/transactions/${transactionId}/shipment/request_return_details`,
  )

export const createReturnShipment = (transactionId: number) =>
  api.post(`/transactions/${transactionId}/shipment/return_shipment`)

export const createComplaintEscalation = (args: CreateComplaintEscalationArgs) =>
  api.post(`/complaints/${args.complaintId}/escalate`, {
    english_allowed: args.englishResponseAllowed,
    help_center_session_id: args.helpCenterSessionId,
  })

export const sendIntellectualPropertyInfringement = (
  formData: SendIntellectualPropertyInfringementArgs,
) => api.post('/brand_complaints', sendIntellectualPropertyInfringementArgsToParams(formData))

export const uploadReportAttachment = (file: File) => {
  const data = new FormData()
  data.append('file', file)

  return api.post<UploadReportAttachmentResp>('/brand_complaints/attachments', data)
}

export const getUserBuyCount = () =>
  api.get<GetUserBuyCountResp>('/tracker_attributes', {
    params: {
      type: 'buy_debit_transactions',
    },
  })

export const getClosetPromotionPricing = () =>
  api.get<ClosetPromotionPricingResp>('/promoted_closets/pricing')

export const downloadShippingLabel = (transactionId: number) =>
  api.get<DownloadShippingLabelResp>(`/transactions/${transactionId}/shipment/pdf_label`)

export const downloadPickupLabel = (transactionId: number) =>
  api.get<DownloadPickupParcelLabelResp>(
    `/transactions/${transactionId}/shipment/parcel_collection_code_pdf`,
  )

export const blockUser = ({ currentUserId, blockedUserId }: UserBlockArgs) =>
  api.post(`/users/${currentUserId}/user_hates`, { hated_user_id: blockedUserId })

export const unblockUser = ({ currentUserId, blockedUserId }: UserBlockArgs) =>
  api.delete(`/users/${currentUserId}/user_hates`, { params: { hated_user_id: blockedUserId } })

export const getDirectDonationConfiguration = () =>
  api.get<GetDirectDonationConfiguratioResp>('/direct_donations/orders/new')

export const createDirectDonationOrder = (args: CreateDirectDonationOrderArgs) =>
  api.post<CreateDirectDonationOrderResp>(
    '/direct_donations/orders/prepare',
    createDirectDonationOrderArgsToParams(args),
    { headers: moneyObjectHeader },
  )

export const getDirectDonationOrder = (orderId: number) =>
  api.get<GetDirectDonationOrderResp>(`direct_donations/orders/${orderId}`, {
    headers: moneyObjectHeader,
  })

export const getNearbyShippingOptions = (args: GetNearbyShippingOptionsArgs) =>
  api.get<NearbyShippingOptionsResp>(
    `/transactions/${args.transactionId}/nearby_shipping_options`,
    {
      params: getNearbyShipmentOptionsArgsToParams(args),
      headers: moneyObjectHeader,
    },
  )

export const getNearbyPickupPoints = (args: GetNearbyPickupPointsArgs) =>
  api.get<NearbyPickupPointsResp>(`/shipping_orders/${args.shippingOrderId}/nearby_pickup_points`, {
    params: getNearbyPickupPointsArgsToParams(args),
    headers: moneyObjectHeader,
  })

export const getPackageShippingOptions = ({
  packageSizeId,
  serviceType,
}: GetPackageShippingOptionsArgs) =>
  api.get<GetPackageShippingOptionsResp>(`/package_sizes/${packageSizeId}/shipping_options`, {
    params: {
      offline_verification_service: serviceType,
    },
  })

export const getRoktTransactionsAttributes = ({
  transactionId,
}: GetRoktTransactionAttributesArgs) =>
  api.get<GetRoktTransactionAttributesResp>(
    `/ads/rokt?transaction_id=${transactionId}&cart_items=true`,
  )

export const getShipmentItems = (transactionId: number) =>
  api.get<GetShipmentItemsResp>(`/transactions/${transactionId}/shipment/items`)

export const getReturnShippingOptions = (transactionId: number) =>
  api.get<GetReturnShippingOptionsResp>(
    `/transactions/${transactionId}/shipment/return_shipping_options`,
  )

export const createCustomReturnShipment = ({
  transactionId,
  returnShippingOption,
}: CreateCustomReturnShipmentArgs) =>
  api.post(`/transactions/${transactionId}/shipment/return_shipping_option`, {
    return_shipping_option: returnShippingOption,
  })

export const getFundraiserConfiguration = () => api.get<GetFundraiserResponse>('/fundraiser')

export const getCharities = () => api.get<GetCharitiesResponse>('/charities')

export const updateFundraiser = ({ percentage, charityCode }: UpdateFundraiserArgs) =>
  api.put<GetFundraiserResponse>('/fundraiser', {
    fundraiser: {
      charity_code: charityCode,
      percentage,
    },
  })

export const getItemShippingDetails = (itemId: number) =>
  api.get<GetShippingDetailsResp>(`/items/${itemId}/shipping_details`, {
    headers: moneyObjectHeader,
  })

export const deleteFundraiser = () => api.delete('/fundraiser')

export const getBrazeAuthToken = () => api.get<GetBrazeAuthTokenResp>('/external_crm/jwts')

export const getConversation = (conversationId: number | string) =>
  api.get<ConversationResp>(`/conversations/${conversationId}`)

export const getNationalities = () => api.get<GetNationalitiesResp>('/nationalities')

export const markAsSold = ({ itemId, buyerId, closingAction, price, currency }: MarkAsSoldArgs) =>
  api.post<MarkAsSoldResp>(
    `/items/${itemId}/mark_as_sold`,
    {
      receiver_id: buyerId,
      closing_action: closingAction,
      price,
      currency,
    },
    { headers: moneyObjectHeader },
  )

export const markAsUnreserved = ({ itemId }: { itemId: number }) =>
  api.delete(`items/${itemId}/reservation`)

export const getFaqEntryFeedbackData = (faqId: number) =>
  api.get<FaqEntryFeedbackResp>(`/faq_entries/${faqId}/feedback`)

export const updateFaqEntryFeedbackForm = (args: UpdateFaqEntryFeedbackArgs) =>
  api.patch(`/faq_entries/${args.faqId}/feedback`, updateFaqEntryFeedbackArgsToParams(args))

export const getVerificationPrompt = (userId: number) =>
  api.get<VerificationPromptResp>(`/users/${userId}/verifications/prompt`)

export const dismissVerificationPrompt = (userId: number) =>
  api.delete(`/users/${userId}/verifications/prompt`)

export const sendEmailVerificationCode = ({ userId, email }: SendEmailVerificationCodeArgs) =>
  api.post<SendEmailVerificationCodeResp>(`/users/${userId}/verifications/email_code`, {
    email,
  })

export const validateEmailVerificationCode = ({
  userId,
  code,
}: ValidateEmailVerificationCodeArgs) =>
  api.put(`/users/${userId}/verifications/email_code`, {
    code,
  })

export const markAsDeliveredToSender = (transactionId: number) =>
  api.put(`/transactions/${transactionId}/shipment/mark_as_return_to_sender_completed`)

export const confirmTransactionInstructions = (transactionId: number) =>
  api.put(`/transactions/${transactionId}/shipment/confirm_instructions`)

export const getUserSessions = (userId: number) =>
  api.get<GetUserSessionsResp>(`users/${userId}/sessions`)

export const deleteUserSession = ({
  userId,
  sessionId,
  fingerprint,
}: {
  userId: number
  sessionId: number
  fingerprint: string
}) => api.delete(`/users/${userId}/sessions/${sessionId}?fingerprint=${fingerprint}`)

export const getSessionDefaults = () =>
  api.get<GetSessionDefaultsResp>('/configurations/session_defaults')

export const reserveTransactionItems = (transactionId: number, reserved: boolean) =>
  api.put(`/transactions/${transactionId}/reservation`, { reserved })

export const getShippingInstructions = (transactionId: number) =>
  api.get<GetShippingInstructionsResp>(`/transactions/${transactionId}/shipping_instructions`)

export const disableUserHoliday = (userId: number) => api.delete(`/users/${userId}/holiday`)

export const getGoogleIdToken = ({
  code,
  state,
  action,
  isLocalRedirectEnabled,
}: GetGoogleIdTokenArgs) =>
  api.get<GetGoogleIdTokenResp>('/google_user/token', {
    params: {
      code,
      state,
      take_action: action,
      next_local_redirect: isLocalRedirectEnabled,
    },
  })

export const getTaxpayerEducation = () => api.get<TaxpayerEducationResp>('/taxpayers/education')

export const getTaxpayerFormConfiguration = (countryCode: string) =>
  api.get<TaxpayerFormConfigurationResp>(`/taxpayers/form/${countryCode}`)

export const changePassword = ({
  userId,
  currentPassword,
  newPassword,
  fingerprint,
}: ChangePasswordArgs) =>
  api.put(`/users/${userId}/password`, {
    old_password: currentPassword,
    password: newPassword,
    fingerprint,
  })

export const changeForgotPassword = ({
  code,
  password,
  passwordConfirmation,
  fingerprint,
}: ChangeForgotPasswordArgs) =>
  api.post('/users/change_forgot_password', {
    code,
    password,
    password_confirmation: passwordConfirmation,
    fingerprint,
  })

export const getForgotPasswordConfirmationCode = ({
  code,
}: GetForgotPasswordConfirmationCodeArgs) =>
  api.get<GetForgotPasswordConfirmationCodeResp>('/users/forgot_password', {
    params: { code },
  })

export const getCategoryAttributes = (categoryId: number) =>
  api.get<GetCategoryAttributesResp>('/item_upload/catalog_attributes', {
    params: { catalog_id: categoryId },
  })

export const searchCategoryAttributeOptions = (code: string, keyword: string | null | undefined) =>
  api.post<SearchCategoryAttributeOptionsResp>('/item_upload/catalog_attributes/search', {
    code,
    keyword,
  })

export const searchCategory = (keyword: string | null | undefined) =>
  api.get<{ catalog_ids: Array<number> }>('item_upload/catalogs/search', { params: { keyword } })

export const updateTaxpayerFormData = (args: UpdateTaxpayerFormDataArgs) =>
  api.put<ApiResponse>('/taxpayers/form', updateTaxpayerFormDataArgsToParams(args))

export const getTaxpayerFormStatus = () => api.get<TaxpayerFormStatusResp>('/taxpayers/form/status')

export const prepareReturnLabelOrder = ({
  transactionId,
  pickupPointCode,
  packageTypeId,
  addressId,
}) =>
  api.post<ReturnLabelOrderResp>('/return_label/orders/prepare', {
    transaction_id: transactionId,
    pickup_point_code: pickupPointCode,
    package_type_id: packageTypeId,
    address_id: addressId,
  })

export const getUserObligations = (userId: number) =>
  api.get<GetUserObligationsResp>(`/users/${userId}/obligations`)

export const checkIsAlertsGeneratorBusy = () =>
  api.get<GetIsAlertsGeneratorBusyResp>('/admin/alert_generators/is_busy')

export const generateAlertsFromSearchParams = ({
  searchParams,
  reportReason,
}: {
  searchParams: Record<string, string | Array<string> | undefined>
  reportReason: ReportReasonCatalogSystem | undefined
}) =>
  api.post('/admin/alert_generators/execute', {
    item_search_params: searchParams,
    report_reason_code: reportReason,
  })

export const getWalletIncomeSettings = () =>
  api.get<GetIncomeSettingsResp>('/wallet/income_reports/settings')

export const getInvoicesAvailableDates = () =>
  api.get<GetInvoicesAvailableDatesResp>('/invoices/available_dates')

export const getInvoicesByDate = ({ year, month }: { year: string; month: string }) =>
  api.get<GetInvoicesByDateResp>(`/invoices/${year}/${month}`)

export const getFeedbackSummary = (userId: number) =>
  api.get<GetFeedbackSummaryResp>(`/user_feedbacks/summary?user_id=${userId}`)

export const acceptTermsAndConditions = (id: number) => api.put(`/users/${id}/agree_terms`)

export const getInfoBoardPosts = () => api.get<GetInfoBoardPostsResp>('/info_posts')

export const getTermsAndConditions = () =>
  api.get<GetTermsAndConditionsResp>('/terms_and_conditions')

export const getInfoBoardPost = (postId: number) =>
  api.get<GetInfoBoardPostResp>(`/info_posts/${postId}`)

export const getNotificationLandings = (code: string) =>
  api.get<GetNotificationLandingsResp>(`/notification_landings/${code}`)

export const getTermsAndConditionsContent = () =>
  api.get<GetTermsAndConditionsContentResp>('/terms_and_conditions/content')

export const getPrivacyPolicy = () => api.get<GetPrivacyPolicyResp>('/privacy_policy')

export const getIsFundraiserEnabled = () =>
  api.get<GetIsFundraiserEnabledResp>('/fundraiser/enabled')

export const getNearbyDropOffPoints = (args: GetNearbyDropOffPointsArgs) =>
  api.get<NearbyDropOffPointResp>(`/shipments/${args.shipmentId}/nearby_drop_off_points`, {
    params: addNearbyDropOffPointArgsToParams(args),
  })

export const createDsaNoticeAppeal = (args: CreateDsaNoticeAppealArgs) =>
  api.post<CreateDsaNoticeAppealResp>('/dsa/appeals/submit', getDsaNoticeCreateAppealArgs(args))

export const createDsaReporterAppeal = (args: CreateDsaNoticeAppealArgs) =>
  api.post<CreateDsaNoticeAppealResp>(
    '/dsa/reporter_appeals/submit',
    getDsaNoticeCreateAppealArgs(args),
  )

export const createDsaNtdAppeal = (args: CreateDsaNtdAppealArgs) =>
  api.post('/dsa/ntd_appeals/submit', getCreateDsaNtdAppealArgs(args))

export const getTaxRules = (countryId: string, variant?: string) =>
  api.get<GetTaxRulesResp>(`/taxpayers/tax_rules/${countryId}?variant=${variant || ''}`)

export const getReferrer = () => api.get<GetReferrerResp>('/referrals/referrer')

export const postAdvertisingLeadForm = (args: PostAdvertisingLeadFormArgs) =>
  api.post('/ads/lead', postAdvertisingLeadFormArgsToParams(args))

export const getSellerOfferOptions = ({ transactionId }: GetSellerOfferOptionsArgs) =>
  api.get<GetSellerOfferOptionsResp>(`/transactions/${transactionId}/offers/seller_options`)

export const getIconsExplanation = (itemId: number) =>
  api.get<GetIconsExplanationResp>(`items/${itemId}/badges`)

export const markFirstTimeListerEducationAsRead = () =>
  api.delete('conversation_safety/education/first_time_listers')

export const dismissTaxpayerRestrictionModal = () =>
  api.post('/taxpayers/banners/taxpayer_banner/dismiss_modal')

export const dismissTaxpayerRestrictionBanner = () =>
  api.post('/taxpayers/banners/taxpayer_banner/dismiss')

export const dismissTaxpayerFillFormModal = () =>
  api.post('/taxpayers/banners/taxpayer_banner/mark_redirected')

export const getTaxpayersSpecialVerificationDeadline = () =>
  api.get<GetTaxpayersSpecialVerificationDeadlineResp>('/taxpayers/special_verification/deadline')
export const getTaxpayersSpecialVerificationEducation = () =>
  api.get<TaxpayerEducationResp>('/taxpayers/special_verification/education')

export const getTaxpayersSpecialVerificationFormConfiguration = () =>
  api.get<GetTaxpayersSpecialVerificationFormConfigurationResp>(
    '/taxpayers/special_verification/configuration',
  )

export const validateTaxpayersSpecialVerificationFormFields = (
  args: ValidateSpecialVerificationFormArgs,
) =>
  api.post('/taxpayers/special_verification/validation', {
    fields: args,
  })

export const submitTaxpayersSpecialVerificationForm = (
  args: SubmitTaxpayersSpecialVerificationFormArgs,
) =>
  api.post(
    '/taxpayers/special_verification',
    submitTaxpayersSpecialVerificationFormArgsToParams(args),
    {
      headers: args.x_thumbprint ? { 'X-Thumbprint': args.x_thumbprint } : {},
    },
  )

export const startMigrationWithId = (migrationCode: string) =>
  api.post('/acquisitions/migrations', {
    migration_code: migrationCode,
  })

export const validateMigrationId = (migrationCode: string) =>
  api.get<GetMigrationStatusResp>(
    `acquisitions/migrations/availability?migration_code=${migrationCode}`,
  )

export const inviteStaff = (email: string) =>
  api.post<InviteStaffResp>('secondary_users/invitation', { email })

export const getAccountStaff = () => api.get<GetAccountStaffResp>('secondary_users/staff')

export const deleteAccountStaffInvitation = (invitationId: number) =>
  api.delete(`secondary_users/staff/${invitationId}`)

export const acceptAccountInvitation = (token: string) =>
  api.post('/secondary_users/invitation/accept', { token })

export const getStaffAccountAssertion = (targetUserId: number) =>
  api.post<GetStaffAccountAssertionResp>('secondary_users/assertions', {
    target_user_id: targetUserId,
  })

export const getDownloadLabelUrl = (shipmentId: number) =>
  api.get<GetDownloadLabelUrlResp>(`shipments/${shipmentId}/label_url`)

export const getTransactionEscrowFees = ({ transactionId }: { transactionId: number }) =>
  api.get<GetItemEscrowFeesResp>(`/transactions/${transactionId}/escrow_fees`)

export const getComplaintCreationDetails = (transactionId: number) =>
  api.get<GetComplaintCreationDetailsResp>(
    `transactions/${transactionId}/complaint_creation_details`,
  )

export const getVasEntryPoints = ({ bannerNames }: GetVasEntryPointsArgs) =>
  api.get<GetVasEntryPointsResp>('/vas_entry_points', {
    params: { names: bannerNames },
  })

export const getRefundEstimate = (transactionId: number, orderItemIds: string) =>
  api.get<RefundEstimateDto>(`transactions/${transactionId}/refund_estimate`, {
    params: { order_item_ids: orderItemIds },
  })

export const getIsNewComplaintViewEnabled = (faqEntryId: number) =>
  api.get<GetIsNewComplaintViewResp>(`faq_entries/${faqEntryId}/complaint_view`)

export const updateShippingDeadlineExtension = (args: UpdateShippingDeadlineExtensionArgs) =>
  api.patch(`/shipments/${args.shipmentId}/deadline_extension_status`, {
    status: args.status,
  })

export const getDeadlineExtensionOptions = (shipmentId: number) =>
  api.get<GetDeadlineExtensionOptionsResp>(`shipments/${shipmentId}/deadline_extension_options`)

export const createShippingDeadlineExtension = (args: CreateShippingDeadlineExtensionArgs) =>
  api.post(`shipments/${args.shipmentId}/deadline_extension`, {
    deadline_extension: {
      key: args.key,
      reason: args.reason,
    },
  })

export const validateTaxpayerForm = (args: ValidateTaxpayerFormArgs) =>
  api.post('/taxpayers/form/validate', { taxpayer: args })

export const getCollectionDates = (shipmentId: number) =>
  api.get<GetCollectionDatesResp>(`shipments/${shipmentId}/collection_dates`)

export const getPriceEstimateWithFees = (args: GetPriceEstimateWithFeesArgs) =>
  api.post<GetPriceEstimateWithFeesResponse>('offer/estimate_with_fees', args)

export const getLabelOptions = (shipmentId: number) =>
  api.get<GetLabelOptionsResp>(`shipments/${shipmentId}/label_options`)

export const getTwoFADetails = (twoFaId: string) =>
  api.get<GetTwoFADetailsResp>(`user_verifier/${twoFaId}/details`)

export const createProIntegrationsApplication = (data: CreateProIntegrationsApplicationArgs) =>
  api.post('/vpi/application', data)

export const resendTwoFactorVerifierCode = (id: string) =>
  api.post<TwoFactorResendResp>(`/user_verifier/${id}/resend`)

export const getTaxpayerNavigationInfo = () =>
  api.get<GetTaxpayerNavigationInfoResp>('/taxpayers/navigation')

export const getTaxpayerCenterInfo = () => api.get<GetTaxpayerCenterConfigResp>('/taxpayers/center')

export const getSellerInsights = () =>
  api.get<GetSellerInsightsResp>('/seller_insights/closet_stats')

export const getSellerInsightsPhotoRecommendations = () =>
  api.get<GetSellerInsightsPhotoRecommendationsResp>('/seller_insights/recommendations/add_photos')

export const getSustainabilityImpact = () =>
  api.get<GetSustainabilityImpactResp>('/seller_insights/sustainability_impacts')

export const getCategoryTips = () => api.get<GetCategoryTipsResp>('/seller_insights/category_tips')

export const getHelpCenterLinksForMigration = () =>
  api.get<GetHelpCenterLinkForUserMigrationResp>('/acquisitions/help')

export const getSellerFilters = () => api.get<GetSellerFiltersResp>('/closet/seller_filters')

export const getDataExportList = (userId: number) =>
  api.get<DataExportListDto>(`users/${userId}/personal_data_export/list`)

export const getDataExportLink = (userId: number, hash: string) =>
  api.get<GetDataExportLinkResp>(`users/${userId}/personal_data_export/download?hash=${hash}`)

export const getAvailableCarriers = ({
  shippingOrderId,
  selectedDeliveryType,
  shipmentId,
}: GetAvailableCarriersArgs) =>
  api.get<GetAvailableCarriersResp>(
    `shipping_orders/${shippingOrderId}/available_rates?delivery_type=${selectedDeliveryType}&shipment_id=${shipmentId}&exclude_current_rate=true`,
  )

export const getAvailablePickupPoints = ({
  shippingOrderId,
  countryCode,
  latitude,
  longitude,
  shipmentId,
  rateRootUuid,
}: GetAvailablePickupPointsArgs) =>
  api.get<GetAvailablePickupPointsResp>(
    `shipping_orders/${shippingOrderId}/nearby_pickup_points?country_code=${countryCode}&latitude=${latitude}&longitude=${longitude}&shipment_id=${shipmentId}&rate_root_uuid=${rateRootUuid}`,
  )

export const getDeliveryOptionChangeRequestDetails = (shipmentId: number) =>
  api.get<GetDeliveryOptionChangeRequestDetailsResp>(
    `shipments/${shipmentId}/delivery_option_change_request`,
  )

export const getDeliveryType = (shipmentId: number) =>
  api.get<getDeliveryTypeResp>(`shipments/${shipmentId}`)

export const createDeliveryOptionChangeRequest = ({
  shipmentId,
  rootRateUuid,
  reason,
}: CreateDeliveryOptionChangeRequestArgs) =>
  api.post(`shipments/${shipmentId}/delivery_option_change_request`, {
    root_rate_uuid: rootRateUuid,
    reason,
  })

export const updateDeliveryOptionChangeRequest = (args: UpdateDeliveryOptionChangeRequestArgs) =>
  api.patch(`/shipments/${args.shipmentId}/delivery_option_change_request`, {
    status: args.status,
    pickup_point_code: args.pickupPointCode,
  })

export const getShipmentDetails = (shipmentId: number) =>
  api.get<GetShipmentDetailsResp>(`/shipments/${shipmentId}`)

export const moderateFeedback = ({ feedbackId, userId, otherUserId }: ModerateFeedbackArgs) =>
  api.post('/notices/feedbacks', {
    feedback_id: feedbackId,
    user_id: userId,
    other_user_id: otherUserId,
  })

export const getPublicPhotos = ({ itemIds, photoSize }: GetPublicPhotosArgs) =>
  api.get<GetPublicPhotosResp>('/items/public_photos', {
    params: {
      item_ids: itemIds,
      photo_size: photoSize,
    },
  })

export const getBumpableItems = ({ currentPage, perPage }: GetBumpableItemsArgs) =>
  api.get<GetBumpableItemsResp>('/bumps/bumpable_items', {
    params: { per_page: perPage, page: currentPage },
  })

export const getAlertItem = (itemId: number) => api.get<GetAlertItemResp>(`/alert_item/${itemId}`)

export const getUsernameSuggestion = (fullname: string) =>
  api.post<GenerateUsernameSuggestionResp>('/users/generate_username', {
    fullname,
  })

export const getTaxpayerReports = () => api.get<GetTaxpayerReportsResp>('/taxpayers/reports')

export const getParcelDetails = ({
  width,
  length,
  height,
  weight,
  catalogId,
}: GetParcelDetailsArgs) =>
  api.get<GetParcelDetailsResp>('/parcels/details', {
    params: {
      width,
      length,
      height,
      weight,
      catalog_id: catalogId,
    },
  })

export const getBumpsItemsSelectionBanner = () =>
  api.get<GetBumpsItemsSelectionBannerResp>('/bumps/items_selection_banner')

export const getBumpsNewMemberItemUploadMotivation = () =>
  api.get<GetBumpsNewMemberItemUploadMotivationResp>('/bumps/new_member_item_upload_motivation')

export const getIsWalletStatementAvailable = () =>
  api.get<GetWalletStatementAvailableResp>('/wallet/statements/download_available')

export const getEscrowOrderShipment = (orderId: number) =>
  api.get<GetEscrowOrderShipmentResp>(`/escrow_orders/${orderId}/shipment`)

export const getEscrowOrderItems = (orderId: number) =>
  api.get<GetEscrowOrderItemsResp>(`/escrow_orders/${orderId}/items`)

export const getEscrowOrderOppositeUser = (orderId: number) =>
  api.get<GetEscrowOrderOppositeUserResp>(`/escrow_orders/${orderId}/opposite_user`)

export const getEscrowOrderComplaintInfo = (orderId: number) =>
  api.get<GetEscrowOrderComplaintInfoResp>(`/escrow_orders/${orderId}/complaint_info`)

export const updateUsername = ({ userId, username }: UpdateUsername) =>
  api.put(`/users/${userId}/username`, {
    username,
  })

export const getUsernameChangeAvailability = (userId: number) =>
  api.get<GetUsernameChangeAvailabilityResp>(`/users/${userId}/can_change_username`)

export const getEscrowOrderActions = (orderId: number) =>
  api.get<EscrowOrderActonsDto>(`/escrow_orders/${orderId}/actions`)
